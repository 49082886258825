import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import backendTranslationsEN from "./backendTranslationsEN.js";
import backendTranslationsPL from "./backendTranslationsPL.js";

i18n.use(initReactI18next).init({
  resources: {
    pl: {
      translation: Object.assign({}, backendTranslationsPL, {
        // SEO
        "seo.subpage.main.title": "Rezerwacja stolika online – MojStolik.pl",
        "seo.subpage.main.description":
          "Zarezerwuj stolik online w najpopularniejszych restauracjach w Polsce. Pobierz aplikację, wybierz, gdzie chcesz zamówić stolik i rezerwuj. Z MojStolik.pl to ultraproste.",
        "seo.subpage.forRestaurant.title":
          "Platforma rezerwacyjna dla restauracji - MojStolik.pl",
        "seo.subpage.forRestaurant.description":
          "MojStolik.pl to platforma do zarządzania rezerwacjami w restauracjach. Wszystkie rezerwacje w 1 miejscu. Dodatkowa platforma komunikacji z gośćmi restauracji.",
        "seo.subpage.aboutUs.title":
          "Ultraprosta aplikacja dla lubiących jadać na mieście.",
        "seo.subpage.aboutUs.description":
          "Setki najpopularniejszych i najbardziej cenionych restauracji w kraju i tysiące użytkowników korzystających z platformy MojStolik.pl. Niech stolik czeka na Ciebie i nigdy odwrotnie.",
        "seo.subpage.howItWork.title":
          "Pobierz aplikację MojStolik.pl na telefon i rezerwuj stoliki",
        "seo.subpage.howItWork.description":
          "Wystarczy tylko kilka kliknięć, aby to stolik w restauracji czekał na Ciebie. To Ty decydujesz, kiedy, gdzie i z kim chcesz zjeść obiad czy kolację. Pobierz aplikację i rezerwuj stoliki.",
        "seo.subpage.documents.title":
          "Sprawdź, jakie korzyści uzyskasz ze współpracy z MojStolik.pl",
        "seo.subpage.documents.description":
          "Zastanawiasz się czy warto? Sprawdź najważniejsze informacje w dokumentach MojStolik.pl. Umowy, oferty marketingowe i korzyści ze współpracy.",
        "seo.subpage.privacyPolicy.title":
          "MojStolik.pl jako administrator danych osobowych",
        "seo.subpage.privacyPolicy.description":
          "Przeczytaj istotne informacje i poznaj platformę MojStolik.pl",
        "seo.subpage.cookiesPolicy.title":
          "MojStolik.pl - poznaj restauracje w Twojej okolicy",
        "seo.subpage.cookiesPolicy.description":
          "MojStolik.pl używa plików cookies. Poznaj z nami najbardziej cenione restauracje w Twojej okolicy.",
        "seo.subpage.registration.title":
          "Zarejestruj się i korzystaj z aplikacji już dziś. MojStolik.pl",
        "seo.subpage.registration.description":
          "Rezerwacja stolików jeszcze nigdy nie była tak prosta. Wygodne płatności, dowolny czas i liczba osób. Zarejestruj się i już dziś korzystaj z MojStolik.pl",
        "seo.subpage.login.title":
          "Zaloguj się do aplikacji i wybieraj restauracje z MojStolik.pl",
        "seo.subpage.login.description":
          "W naszej ofercie znajdziesz najpopularniejsze restauracje w całej Polsce. Pobierz aplikację, zaloguj się i korzystaj z ultraprostego systemu do rezerwacji stolików.",
        "seo.subpage.covid.restaurant.title":
          "COVID-19 - Odpowiedzialna restauracja",
        "seo.subpage.covid.restaurant.description":
          "Rozwiązania dla restauracji, które pomogą utrzymać biznes w tych trudnych czasach!",
        "seo.subpage.covid.client.title": "COVID-19 - Odpowiedzialni goście",
        "seo.subpage.covid.client.description":
          "Poradnik dla odpowiedzialnych gości odpowiedzialnej restauracji",

        // restaurant page
        /* "forRestaurantsPage.header": "MOJSTOLIK.PL TO PLATFORMA PREZENTACJI NAJBARDZIEJ CENIONYCH MIEJSC GASTRONOMICZNYCH W CAŁEJ POLSCE.",
                "forRestaurantsPage.subheader": "Widząc w tym gronie Twoją restaurację goście wiedzą, że mogą spodziewać się obsługi na najwyższym poziomie.",
                "forRestaurantsPage.platform": "PLATFORMA MOJSTOLIK.PL TO",
                "forRestaurantsPage.1.strong": "to koniec ery księgi i ołówka w Twojej restauracji!",
                "forRestaurantsPage.1.text": "",
                "forRestaurantsPage.2.strong": "to innowacyjna odpowiedź na potrzeby coraz bardziej niecierpliwego i cyfrowego społeczeństwa, ",
                "forRestaurantsPage.2.text": "które nie chce czekać na potwierdzenie rezerwacji i odebranie telefonu przez zabieganego kelnera,",
                "forRestaurantsPage.3.strong": "to inteligentne zarządzenie wszystkimi Twoimi rezerwacjami, ",
                "forRestaurantsPage.3.text": "ze wszystkich kanałów i to w czasie rzeczywistym w jednym prostym systemie (strona WWW, FB, Google, aplikacja MojStolik, portal MojStolik, telefon i rezerwacji „walk-in”), już nie musisz zaglądać w kilka miejsc,",
                "forRestaurantsPage.4.strong": "to zbiór informacji o Twoich gościach, ",
                "forRestaurantsPage.4.text": "który pozwala Ci być z nimi w stałym kontakcie i zaskakiwać uważnością na potrzeby oraz przyzwyczajenia,",
                "forRestaurantsPage.5.strong": "to Twoja profesjonalna baza danych,",
                "forRestaurantsPage.5.text": " a my dbamy o jej bezpieczeństwo i zgodność z RODO,",
                "forRestaurantsPage.6.strong": "to nowoczesne narzędzie do kontaktowania się z Twoimi gośćmi,",
                "forRestaurantsPage.6.text": " potwierdzania wizyty oraz przypominania o sobie nawet w czasie „lockdown”, ",
                "forRestaurantsPage.7.strong": "to przejaw Twojej odpowiedzialności w czasie COVID-19 –",
                "forRestaurantsPage.7.text": " masz kontakt z gośćmi i zarządzasz ograniczeniami,",
                "forRestaurantsPage.8.strong": "to uporządkowanie i usprawnienie pracy zespołu,",
                "forRestaurantsPage.8.text": "",
                "forRestaurantsPage.9.strong": "to możliwość automatycznej, telefonicznej rezerwacji stolika",
                "forRestaurantsPage.9.text": " w restauracji o każdej porze dnia i nocy, bez angażowania zespołu. Dzięki usłudze IRV „nikt nie odejdzie z kwitkiem” i nikt nie będzie musiał czekać na odpowiedź,",
                "forRestaurantsPage.10.strong": "to system powstały we współpracy z najbardziej obleganymi restauracjami w Polsce -",
                "forRestaurantsPage.10.text": " słuchamy i wychodzimy naprzeciw Twoim potrzebom,",
                "forRestaurantsPage.11.strong": "to stale dostępny help desk w Polsce oraz codzienne wsparcie marketingowe i PR,",
                "forRestaurantsPage.11.text": "",
                "forRestaurantsPage.12.strong": "to technologiczne szkolenia,",
                "forRestaurantsPage.12.text": "",
                "forRestaurantsPage.13.strong": "to towarzystwo najbardziej świadomych restauratorów w Polsce,",
                "forRestaurantsPage.13.text": ", którzy wiedzą jak sprawnie zarządzać swoim biznesem,",
                "forRestaurantsPage.14.strong": "",
                "forRestaurantsPage.14.text": "to dużo więcej…",
                "forRestaurantsPage.form.title": "Jeśli chciałbyś/chciałabyś dowiedzieć się więcej o platformie MojStolik.pl i o zaletach współpracy z nami wypełnij proszę ten krótki formularz a skontaktujemy się z Tobą:", */

        "covid.restaurant.1.strong": "PLAN B: ",
        "covid.restaurant.1.text":
          "Już teraz przygotuj SWÓJ plan awaryjny na wypadek wzrostu liczby zachorowań – pomyśl jakie masz możliwości manewru i alternatywy w razie ponownego wprowadzenia obostrzeń różnego stopnia, jak przeorganizujesz pracę, jakie wprowadzisz procedury, jak utrzymasz kontakt z gośćmi, zagospodarujesz środki finansowe i zaplanujesz pracę ludzi. Jesteś bogatszy o wiosenne doświadczenia. Nie daj się zaskoczyć drugi raz.",
        "covid.restaurant.2.strong":
          "BEZPIECZEŃSTWO I HIGIENA to już standard, ale…: ",
        "covid.restaurant.2.text":
          "Nie odpuszczaj tematu. Sprawdź, czy wszystkie zalecane procedury związane z bezpieczeństwem gości i pracowników działają bez zarzutu – maseczki na twarzach kelnerów, dezynfekcja stołów po każdym z gości, elektroniczne menu jako opcja dla gości, procedury mycia i dezynfekcji rąk, zachowanie odpowiedniego dystansu pomiędzy stolikami i maksymalne ułatwienie płatności bezgotówkowych. Nie bój się grzecznie instruować gości, jak mają się zachowywać u Ciebie w restauracji.",
        "covid.restaurant.3.strong": "BUDUJ SWOJĄ SPOŁECZNOŚĆ: ",
        "covid.restaurant.3.text":
          "Z naszych obserwacji wynika, że te restauracje, które zdołały zbudować wokół siebie społeczność, a podczas lockdown utrzymywały kontakt ze swoimi gośćmi poprzez media społecznościowe lub SMSy wysyłane z aplikacji MojStolik.pl poradziły sobie zdecydowanie lepiej w nowej rzeczywistości, niż te, które nie komunikowały się w odpowiedni sposób. Zadbaj zatem już teraz o stały, ciekawy i rzetelny kontakt ze swoimi gośćmi – zadbaj o social media, stronę, a także pomyśl, jak jeszcze będziesz mógł się komunikować w razie takiej potrzeby. Na szczęście rząd podczas swoich majowych prac wycofał się z obowiązku posiadania przez każdą restaurację bazy danych swoich gości, ale jako odpowiedzialny restaurator powinieneś taką bazę budować. Tu bardzo pomocne jest wykorzystanie platformy MojStolik.pl – maksymalnie ułatwia ona zbieranie i przechowywanie danych (imię i nazwisko, mail, telefon) gości zgodnie z RODO. Dane należą do restauracji.",
        "covid.restaurant.4.strong": "KONIECZNA REZERWACJA? ",
        "covid.restaurant.4.text":
          "W wielu krajach Europy i Świata pojawiały się pomysły, aby do restauracji można było pójść tylko po uprzednim zarezerwowaniu stolika. Bądź zatem przygotowany na taką ewentualność i zadbaj o technologię.",
        "covid.restaurant.5.strong": "STARAJ SIĘ UŚWIADAMIAĆ SWOICH GOŚCI – ",
        "covid.restaurant.5.text":
          "za pomocą mediów społecznościowych, ale też innych kanałów komunikuj, dlaczego przestrzeganie zasad bezpieczeństwa w restauracji jest tak ważne i jakie procedury wprowadziłeś u siebie, aby zminimalizować ryzyko zarażenia. Uświadamiaj im też, że niektóre ich zachowania narażają Cię na straty, na które w tym trudnym momencie nie możesz sobie pozwolić. Chodzi tu głównie o niewykorzystane rezerwacje, przez które tracisz czas i pieniądze. Niektórzy po prostu nie zdają sobie sprawy z tego, jak duży jest to problem dla restauratorów. Dzięki platformie Mojstolik.pl proces odwołania rezerwacji staje się bardzo prosty dla gości i jest całkowicie automatyczny po stronie restauracji.",
        "covid.restaurant.6.strong": "SZUKAJ OSZCZĘDNOŚCI: ",
        "covid.restaurant.6.text":
          "Szczególnie dzisiaj każdy gość to skarb i nie pozwólmy mu czekać na odebranie rezerwacji przez zabieganego kelnera, który przy ograniczonym składzie osobowym ma znacznie więcej obowiązków i może nie zdążyć dobiec do telefonu. Z naszych wyliczeń wynika, że system rezerwacyjny pozwala zaoszczędzić miesięcznie ponad 20 godzin pracy kelnera lub hosta, ponieważ około 50% rezerwacji automatycznie przechodzi z telefonu do internetu i dociąża Twój zespół. Czysta oszczędność!",
        "covid.restaurant.7.strong": "OPTYMALIZACJA WYKORZYSTANIA SALI: ",
        "covid.restaurant.7.text":
          "W przypadku zmniejszonego obłożenia restauracji, optymalizacja zarządzania rezerwacjami i wykorzystaniem stolików jest sprawą kluczową dla biznesu. Sprawdź technologiczne gotowe rozwiązania, które ułatwią kontrolę nad rezerwacjami wpływającymi ze wszystkich kanałów komunikacji: strona, aplikacja, social media, telefon, walk-in. Takim rozwiązaniem jest platforma MojStolik.pl.",
        "covid.restaurant.8.strong": "MINIMALIZACJA EFEKTU NON-SHOW: ",
        "covid.restaurant.8.text":
          "Rezerwacja, która nie jest odwołana, ale gość się nie pojawia to zmora restauratorów. W momencie, kiedy obłożenie lokalu dozwolone jest tylko na poziomie 55-75% takie zachowania gości mają realne konsekwencje finansowe. Dlatego postaraj się zminimalizować ryzyko takich zdarzeń. MojStolik.pl wysyła gościom przypomnienia SMS o rezerwacji. Jej ewentualne odwołanie to kwestia kliknięcia w wysłany link. Korzystając z tego rozwiązania zwiększasz możliwość pełnego wykorzystania potencjału swojej restauracji i unikasz strat. W Londynie gastronomowie zbuntowali się, bo to nie jest czas na stratę ich szansy zarabiania.",
        "covid.restaurant.9.strong": "BEZPIECZNE MENU: ",
        "covid.restaurant.9.text":
          "Zalecane jest, aby menu w restauracjach były jednorazowe i wyrzucane za każdym razem, kiedy trafi do czyiś rąk. Takie obostrzenia wprowadzone zostały jako obowiązek np. w niektórych stanach USA. Nie jest to ani ekonomiczne ani ekologiczne. Może zatem warto zastanowić się nad opcją prezentacji swojego menu w smartfonie gościa? Użytkownicy MojStolik.pl już mają taką możliwość. Kelner zyskuje możliwość zaproponowania gościom, którzy ściągnęli aplikację i w ten sposób zapoznali się z menu. Można też przed wizytą z aplikacji wysłać SMS z linkiem i poprosić gości, aby już wcześniej wprowadzili się w klimat wieczoru wybierając dania.☺ Aplikację można też pobrać przy stoliku przez QR kod. Szybka, nowoczesna i pomysłowa opcja, dla niektórych gości. Warto ją proponować.",
        "covid.restaurant.10.strong": "DOSTOSUJ KUCHNIĘ: ",
        "covid.restaurant.10.text":
          "Menu powinno być dosyć krótkie i w większości oparte na produktach sezonowych, lokalnych lub po prostu łatwo dostępnych. Inne warianty to ryzyko, chyba że nastawiasz się na dodatkową kartę dań – łatwą do wymiany. W razie problemów i nowych obostrzeń, np. z przemieszczaniem się na długich dystansach lub zamknięciem granic, nie będziesz miał problemów z dostawami i zdobyciem produktów dla szefa kuchni. Przeorganizowanie pracy kuchni z dnia na dzień będzie znacznie prostsze. Nie bez znaczenia są też koszty.",
        "covid.restaurant.11.strong": "POBIERAJ ODPOWIEDZIALNYCH DOSTAWCÓW: ",
        "covid.restaurant.11.text":
          "Szukaj partnerów w biznesie. Firmy, które pomogą, zrozumieją i dadzą nowe rozwiązania. Poszukaj dostawców wysokojakościowych produktów w sąsiedztwie swojego biznesu – poleganie na długodystansowych dostawach i trudno dostępnych produktach jest w obecnych czasach zbyt ryzykowne. Informacje co robią firmy dla swoich restauracji/partnerów znajdziesz na portalu www.fortalks.pl.",
        "covid.restaurant.header": "PORADNIK DLA ODPOWIEDZIALNEJ RESTAURACJI",
        "covid.restaurant.title": "PORADNIK DLA ODPOWIEDZIALNEJ RESTAURACJI",
        "covid.restaurant.text1":
          "Wszyscy wiemy, że restauracje w Polsce walczą o przetrwanie, dlatego mają w większości wysokie standardy pracy, higieny i komunikacji z gośćmi. Pójdźmy krok dalej. Platforma MojStolik.pl i zespół FOR Solutions wypracowały system i pakiet gotowych rozwiązań na wypadek nowych obostrzeń i ewentualną drugą falę pandemii. Celem poradnika jest pomoc restauracjom w biznesowym przetrwaniu i też maksymalne ograniczenie niebezpieczeństwa zakażeń koronawirusem.",
        "covid.restaurant.text2":
          "Pierwsza fala koronawirusa i związany z nią lockdown był dla rynku gastronomicznego prawdziwym szokiem. Otrząśnięcie się z niego i przejście do działania w nowej rzeczywistości zajęło wszystkim trochę czasu. Teraz jesteśmy już oswojeni z sytuacją, bogatsi w wiedzę i doświadczenia, jak radzić sobie w sytuacjach kryzysowych. Jednak we własnym i dobrze pojętym interesie, wspólnie z gośćmi, restauracje muszą robić wszystko, aby zapobiec rozprzestrzenianiu się wirusa. Zrobiliśmy podsumowanie, jak przygotować swoją restaurację do pracy w obliczu rosnącej ilości zakażeń:",
        "covid.restaurant.welcome1": "Drogi Restauratorze",
        "covid.restaurant.welcome2":
          "Oto 11 rozwiązań, które pomogą utrzymać biznes w tych trudnych czasach!",
        "covid.restaurant.summary":
          "Mamy nadzieję, że ten zbiór porad będzie pomocny w adaptacji Twojej restauracji do nowej rzeczywistości i w przygotowaniu się do ewentualnych utrudnień. W razie dodatkowych pytań, służymy pomocą. Jeśli chcesz uzyskać więcej informacji co do współpracy z nami, wypełnił formularz. Oddzwonimy lub odpiszemy.",
        "covid.restaurant.greetings": "Chcemy pomóc, dlatego łączymy siły!",
        "covid.restaurant.team": "Zespół MojStolik.pl i FOR Solutions.",
        "covid.client.page.title": "PORADNIK DLA ODPOWIEDZIALNYCH GOŚCI",
        "covid.client.dear.guest": "Drogi Gościu",
        "covid.client.description":
          " Bez Ciebie żadna restauracja nie ma racji bytu. TO wszystko dla Ciebie. Jeśli nie będziesz wspierał gastro-biznesu, to zniknie on z ulic naszych miast. A przyznaj, że świat bez barów i restauracji byłby strasznie smutny i pusty, bo spotkania przy stole łączą, bawią i rozwijają nasze życie towarzyskie.",
        "covid.client.description2":
          "Zatem apelujemy – wspieraj swoje ulubione restauracje, odwiedzaj je, ale rób to z głową. W dobie koronawirusa nasza wspólna społeczna odpowiedzialność jest niesamowicie ważna. Zwróć też uwagę na niektóre swoje zachowania, które powodują realne straty w restauracjach. Dajemy ich przykłady, aby pomóc restauracjom działać sprawniej. A na koniec, pamiętaj o wszystkich zasadach bezpieczeństwa tak, aby pomóc utrzymywać restauracjom wysokie standardy pracy, higieny i organizacji. Oni o to dbają! Dbaj i TY!",
        "covid.client.description3":
          "Jak zatem odpowiedzialnie cieszyć się czasem w ulubionych restauracjach? MojStolik.pl i FOR Solutions przygotowało dla Ciebie kilka podpowiedzi:",
        "covid.client.summary":
          "Stosując się do tych prostych zasad zadbasz o bezpieczeństwo swoje i załogi Twojej ulubionej restauracji, a będąc tam i rozkoszując się ich pysznymi daniami, realnie ją wspierasz i pomagasz przetrwać trudny czas. Obraz naszych miast bez restauracji byłby tragiczny…",
        "covid.client.1.strong": "PODSTAWOWE ZASADY HIGIENY – ",
        "covid.client.1.text":
          "czyli jak wchodzisz do lokalu, załóż maseczkę i zdejmij ją, dopiero jak usiądziesz przy stole, zdezynfekuj ręce przed wejściem (restauracje mają obowiązek posiadania dozownika z płynem na wejściu). Jak udajesz się do toalety, ponownie zakryj usta i nos maseczką. Te kwestie są naprawdę kluczowe dla ograniczenia ryzyka zarażenia się COVID-19. ",
        "covid.client.2.strong": "DYSTANS SPOŁECZNY – ",
        "covid.client.2.text":
          "wiemy, że utrzymywanie odpowiedniego dystansu jest kłopotliwe i często się zapominamy, ale wystarczą proste zasady: nie utrudniaj życia zespołowi i nie zmieniaj położenia stolików bez zgody kelnera lub managera. Zostały rozstawione tak, aby zapewnić Tobie bezpieczeństwo.",
        "covid.client.3.strong": "WYBÓR STOLIKA – ",
        "covid.client.3.text":
          "kiedy wchodzisz do restauracji (w maseczce na twarzy!), poczekaj aż kelner wskaże Ci odpowiedni stolik. W ten sposób ułatwisz mu pracę i bezpieczne rozsadzenie gości. Jeśli możesz wybrać miejsce sam to siadaj tylko przy stolikach, które są oznaczone jako zdezynfekowane.",
        "covid.client.4.strong": "REZERWACJA – ",
        "covid.client.4.text":
          "jeśli wiesz, że wybierasz się do restauracji np. dziś wieczorem, zarezerwuj wcześniej stolik. To ułatwia pracownikom restauracji rozplanowanie usadzenia gości, ale również zaplanowanie zaopatrzenia i pracy personelu. Poza tym skraca to maksymalnie czas Twojego kontaktu z personelem, a to jest korzystne i dla Ciebie i dla nich. Łatwo i szybko zarezerwujesz stolik w wybranej restauracji za pomocą aplikacji MojStolik.pl. Po co czekać na stolik, jeśli może on czekać na Ciebie?",
        "covid.client.5.strong":
          "ODWOŁUJ REZERWACJĘ, JEŚLI NIE MOŻESZ PRZYJŚĆ! ",
        "covid.client.5.text":
          "Nie pojawienie się w restauracji pomimo zrobionej rezerwacji jest nie tylko, delikatnie mówiąc, niegrzeczne, ale też naraża restauratora na realne straty. Nie może nikomu przydzielić Twojego stolika, bo przecież jest zarezerwowany. Wziął pod uwagę Ciebie i Twoich współbiesiadników podczas planowania zaopatrzenia. Taka sytuacja to realna strata finansowa dla niego, teraz kiedy i tak obłożenie restauracji jest zmniejszone, jest to szczególnie dotkliwe. Ułatwieniem dla Ciebie będzie też platforma MojStolik.pl – nie tylko przypomni Ci smsem, o której i gdzie szef kuchni na Ciebie czeka, ale też maksymalnie ułatwi Ci odwołanie rezerwacji w razie potrzeby. To tylko kliknięcie w jeden link!",
        "covid.client.6.strong": "BEZDOTYKOWE MENU – ",
        "covid.client.6.text":
          "jeśli masz w swoim smartfonie aplikację do rezerwacji stolików, umożliwi Ci ona też zapoznanie się z menu bez konieczności brania do rąk tego restauracyjnego. Jeśli nie masz aplikacji, ściągnij ją za pomocą specjalnych QR kodów, które masz dostępne przy stolikach.",
        "covid.client.7.strong": "PŁAĆ ZBLIŻENIOWO – ",
        "covid.client.7.text":
          "unikaj bezpośrednich kontaktów i płatności gotówkowych. Płać kartą kredytową, blikiem lub telefonem.",
        "covid.client.8.strong": "NIE KRYTYKUJ – ",
        "covid.client.8.text":
          "to i tak jest bardzo trudny czas dla branży, która praktycznie codziennie walczy o przetrwanie. Dlatego też powstrzymaj się od publicznego wyrażania krytycznych uwag, a skup się na pozytywnych emocjach związanych z biesiadowaniem. Dobrych emocji wszyscy teraz bardzo potrzebujemy, a szczególnie restauracje, które starają podwójnie, aby przetrwać.",
        "covid.client.9.strong": "WSPIERAJ – ",
        "covid.client.9.text":
          "jeśli byłeś zadowolony z wizyty, koniecznie opowiedz o tym w swoich social mediach, zrób INSTA STORIES i baw się dobrze zachwalając dania i przyciągając innych gości do Twojej ulubionej restauracji!",

        /* --forRestaurants page 2.0 - global-- */
        "forRestaurantsPage.global.perMonth": "zł/miesięcznie",
        "forRestaurantsPage.global.perMonth.short": "zł/mies.",
        "forRestaurantsPage.global.button.choose": "WYBIERZ",
        "forRestaurantsPage.global.button.next": "DALEJ",
        "forRestaurantsPage.global.button.back": "WRÓĆ",
        "forRestaurantsPage.global.button.accept": "AKCEPTUJ",
        "forRestaurantsPage.global.button.discover": "ZOBACZ DOSTĘPNE PLANY",
        "forRestaurantsPage.global.button.send": "WYŚLIJ",
        "forRestaurantsPage.global.button.learnMore": "Czytaj więcej",
        /* --forRestaurant page 2.0-- */
        "forRestaurantsPage.apiError":
          "Problemy techniczne. Proszę spróbować później.",
        "forRestaurantsPage.1.header": "MOJSTOLIK.PL TO WIĘCEJ NIŻ SYSTEM",
        "forRestaurantsPage.1.subsection.1.header":
          "PORTAL I APLIKACJA DLA GOŚCI",
        "forRestaurantsPage.1.subsection.1.text":
          "Prosta i wygodna platforma i aplikacja umożliwiająca błyskawiczną rezerwację stolika w Twojej restauracji.",
        "forRestaurantsPage.1.subsection.2.header": "SYSTEM TELEFONICZNY (IVR)",
        "forRestaurantsPage.1.subsection.2.text":
          "System telefoniczny (IVR) przyjmie rezerwacje zamiast Twojego zespołu, który może poświęcić się obsłudze Twoich gości w restauracji.",
        "forRestaurantsPage.1.subsection.3.header":
          "PORTAL I APLIKACJA DLA RESTAURACJI",
        "forRestaurantsPage.1.subsection.3.text":
          "Zarządzanie rezerwacjami i stolikami w sposób automatyczny lub manualnie z aplikacji na tablet lub portalu restauracji.",
        "forRestaurantsPage.2.header":
          "ZARZĄDZAJ Z POZIOMU APLIKACJI LUB PRZEGLĄDARKI",
        "forRestaurantsPage.2.text":
          "Korzystaj z zaawansowanych funkcji aplikacji na tablet oraz interfejsu na przeglądarkę. Zapomnij o książce rezerwacji i przejdź na rozwiązanie uniwersalne dla każdego typu restauracji.",
        "forRestaurantsPage.3.header": "ZAPOMNIJ O NO-SHOW",
        "forRestaurantsPage.3.text":
          "Z systemem MOJSTOLIK.PL minimalizujesz ryzyko pustych stolików przy których powinni siedzieć goście dzięki:",
        "forRestaurantsPage.3.listItem.1":
          "SMS-owym potwierdzeniom i przypomnieniom o dokonanej rezerwacji",
        "forRestaurantsPage.3.listItem.2":
          "Możliwości szybkiego i łatwego anulowania rezerwacji",
        "forRestaurantsPage.3.listItem.3":
          "Analizie historycznej aktywności gości",
        "forRestaurantsPage.4.header":
          "SKUTECZNIE KIERUJEMY RUCHEM DO RESTAURACJI",
        "forRestaurantsPage.5.header": "CO MÓWIĄ O NAS RESTAURATORZY?",
        "forRestaurantsPage.5.opinion.1.title":
          "Mariusz Diakowski, właściciel restauracji Stary Dom:",
        "forRestaurantsPage.5.opinion.1.text":
          "Praca z MojStolik.pl to była dla Stary Dom prawdziwa rewolucja. Ani ja ani zespół restauracji nie wyobrażamy już sobie powrotu do ery „księgi i ołówka”. Faktycznie MojStolik.pl prowadził na naszej restauracji testy swojej platformy. Mieliśmy trochę obaw, ale zdecydowaliśmy się zaryzykować. Absolutnie nie żałujemy! Wszystkie nasze uwagi i sugestie były od razu wdrażane. Dzięki testowaniu „na żywym organizmie” ten system naprawdę odpowiada potrzebom restauracji z dużym obłożeniem, niesamowicie ułatwia pracę i skraca jej czas. Koniec z długopisami, ołówkami, korektorami sklejającymi strony, nieczytelnym pismem i nie praktycznymi księgami. Teraz wszystko jest uporządkowane i proste w obsłudze. Dodam też, że platforma jest praktycznie bezawaryjna, a jeśli jest taka potrzeba to helpdesk działa bez zarzutu.",
        "forRestaurantsPage.5.opinion.2.title":
          "Marek Katrycz, Dyrektor Operacyjny w STIXX Bar&Grill:",
        "forRestaurantsPage.5.opinion.2.text":
          "MojStolik.pl jest to bardzo nowoczesny i nowatorski system, dzięki któremu możemy jako duża restauracja skutecznie zarządzać rezerwacjami Gości oraz rozplanowaniem ich usadzenia, co niesamowicie upraszcza pracę naszego Zespołu. Zdecydowanie polecamy ten system, stale polepszają jego funkcjonalność, jest on intuicyjny oraz bardzo łatwy w obsłudze.Skrócił nam czas przyjmowania rezerwacji oraz odszukiwania ich!, co szczególnie pomaga przy dużym obłożeniu.",
        "forRestaurantsPage.5.opinion.3.title":
          "Robert Goluba, Brand Manager Bombaj Masala:",
        "forRestaurantsPage.5.opinion.3.text":
          "Dlaczego zdecydowaliśmy się na system MojStolik.pl w naszych restauracjach? Dobre opinie w branży plus naprawdę miłe i fachowe podejście do nowego klienta. System jest bardzo intuicyjny, ale szkolenia dla załogi trwały tyle czasu, aż każdy pracownik restauracji umiał obsługiwać panel, dzięki temu nie było problemów z wdrożeniem Mojego Stolika w restauracji. Obyło się bez żadnych problemów, co przy nowych oprogramowaniach jest chyba niespotykane.Wystarczył pierwszy dzień z dużą ilością gości i rezerwacji w naszej restauracji, by przekonać się o zaletach rozwiązań w systemie MojStolik.pl. Jestem pewny, że pozwolił nam on (i nadal to robi) przyjmować płynniej gości i co za tym idzie - więcej gości obsługiwać. To naturalnie przekłada się na wynik finansowy. Gdyby po kilku tygodniach ktoś nakazałby mi powrót do pracy z tradycyjną księgą rezerwacji, czułbym się jak… w XX wieku.",
        "forRestaurantsPage.6.header": "POZNAJ PLANY MOJSTOLIK.PL",
        "forRestaurantsPage.6.subheader":
          "Wybierz plan dla Twojej restauracji i rozpocznij z nami współpracę.",
        "forRestaurantsPage.discountCodeInput": "Wpisz kod promocyjny",
        "forRestaurantsPage.discountCodeButton": "Użyj",
        "forRestaurantsPage.discountCodeLoading": "Trwa ładowanie...",
        "forRestaurantsPage.discountCodeSuccess": "Kod promocyjny wczytany",
        "error.promotion.not.exists": "Podany kod promocyjny nie istnieje",
        "forRestaurantsPage.allOffers":
          "Aby poznać szczegółowe porównanie planów MOJSTOLIK.PL",
        "forRestaurantsPage.allOffers.orange": "przejdź tutaj",
        "forRestaurantsPage.form.title":
          "Jeśli nie jesteś zdecydowana(-y) i chciałabyś/chciałbyś dowiedzieć się więcej o platformie MojStolik.pl proszę wypełnij ten krótki formularz a skontaktujemy się z Tobą:",
        /* --forRestaurant page 2.0 - komponent ofert-- */
        "forRestaurantsPage.offers.offerItem.1.header": "LIGHT",
        "forRestaurantsPage.offers.offerItem.1.text":
          "Dla restauracji, które cenią prostotę automatyzacji procesów kolekcji rezerwacji w czasie rzeczywistym.",
        "forRestaurantsPage.offers.offerItem.2.header": "ADVANCED",
        "forRestaurantsPage.offers.offerItem.2.text":
          "Dla restauracji, które oprócz samych rezerwacji dodatkowo chcą zarządzać salami i stolikami.",
        "forRestaurantsPage.offers.offerItem.3.header": "ADVANCED",
        "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
        "forRestaurantsPage.offers.offerItem.3.text":
          "Dla dużych restauracji, w których pełna automatyzacja wszystkich kanałów komunikacji z gośćmi jest nieodzowna.",
        "forRestaurantsPage.offers.subscription": "Abonament",
        "forRestaurantsPage.offers.commission": "Prowizja",
        "forRestaurantsPage.offers.commission.currency": "zł",
        "forRestaurantsPage.offers.header": "Opcje dodatkowe",
        "forRestaurantsPage.offers.additional-options.prepayments":
          "Moduł przedpłat do rezerwacji",
        "forRestaurantsPage.offers.additional-options.ivr":
          "Automatyczna obsługa rezerwacji telefonicznych (IVR) z dodatkowym pakietem 300 SMS/mc",
        "forRestaurantsPage.offers.additional-options.smsPackage":
          "Pakiet dodatkowych wiadomości SMS - 1000 szt./mc",
        "forRestaurantsPage.offers.mostPopular": "Najczęstszy wybór",
        "forRestaurantsPage.offers.pricingSetting1":
          "Powyższe ceny obowiązują w przypadku rocznego okresu rozliczeniowego. Przełącz na rozliczenie miesięczne: ",
        "forRestaurantsPage.offers.pricingSetting2":
          "Powyższe ceny obowiązują w przypadku miesięcznego okresu rozliczeniowego. Przełącz na rozliczenie roczne: ",
        "forRestaurantsPage.offers.bulletPoint.1":
          "1. Wszystkie ceny w ofercie są cenami netto w zł i w momencie wystawienia faktury zostanie doliczony ustawowy podatek VAT według stawki obowiązującej w dniu wystawienia faktury.",
        "forRestaurantsPage.offers.bulletPoint.2":
          "2. W przypadku rocznego modelu płatności prezentowana jest wysokość miesięcznej opłaty, natomiast płatność jest jednorazowa za 12 miesięcy z góry.",
        "forRestaurantsPage.offers.bulletPoint.3":
          "3. Rabat marketingowy jest przyznawany w wysokość 50 zł netto od miesięcznej opłaty, pod warunkiem udostępnianie przez restaurację linku do rezerwacji poprzez MOJSTOLIK.PL w Social Media restauracji co najmniej 2 razy w każdym miesiącu. ",
        "forRestaurantsPage.offers.bulletPoint.4":
          "4. Koszt SMS-a poza abonamentem wynosi 0,09 zł netto.",
        "forRestaurantsPage.offers.bulletPoint.5":
          "5. W przypadku korzystania z Modułu Przedpłat od każdej transakcji pobierana jest przez operatora płatności prowizja w wysokości 1,59% od kwoty transakcji.",

        /* --forRestaurant page 2.0 - porównanie ofert-- */
        "forRestaurantsPageAllOffers.header": "Poznaj Plany MOJSTOLIK.PL",
        "forRestaurantsPageAllOffers.subheader":
          "Wybierz plan dla Twojej restauracji i rozpocznij z nami współpracę.",
        "forRestaurantsPageAllOffers.offer.1": "LIGHT",
        "forRestaurantsPageAllOffers.offer.2": "ADVANCED",
        "forRestaurantsPageAllOffers.offer.3": "ADVANCED",
        "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
        "forRestaurantsPageAllOffers.listHeader.1":
          "Kanały rezerwacji dostępne dla klienta",
        "forRestaurantsPageAllOffers.listHeader.2":
          "Zarządzanie rezerwacjami w restauracji",
        "forRestaurantsPageAllOffers.listHeader.3":
          "Konfiguracja profilu restauracji",
        "forRestaurantsPageAllOffers.listHeader.4":
          "Dostępność dla restauracji z urządzeń",
        "forRestaurantsPageAllOffers.listHeader.5": "Dodatkowe usługi",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.1":
          "Rezerwacje ze strony www.mojstolik.pl",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
          "Rezerwacje z dedykowanej aplikacji mobilnej MojStolik",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.3":
          "Rezerwacje ze strony www restauracji",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.4":
          "Rezerwacje z Google Maps",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.5":
          "Rezerwacje z Facebook",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
          "Potwierdzenia / przypomnienia o rezerwacij poprzez SMS/Notyfukację aplikacji**",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
          "Automatyczna obsługa rezerwacji telefonicznych",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.8":
          "Rezerwacje z Instagrama restauracji",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
          "Rezerwacje ze skrzynki mailowej (autoresponder)",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
          "Linki / QR kody w artykułach i materiałach PR restauracji",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.1":
          "Lista rezerwacji - widok tabelaryczny",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.2":
          "Rezerwacje na mapie sali",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.3":
          "Widok Gantt'a (grafik rezerwacji)",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.4":
          "Szczegóły i edycja rezerwacji",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.5":
          "Zaawansowane zarządzanie rezerwacjami",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.6":
          "Codzienny raport z rezerwacji",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.7":
          "Obsługa przedpłat do rezerwacji",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.8":
          "Obsługa walk-in",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
          "Historia rezerwacji / analiza upodobań i zachowań gości",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.10":
          "Minimalizacja efektu no-show",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.11":
          "Praca w trybie live",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
          "Baza klientów restauracji / zgodność z RODO",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
          "BEZPIECZEŃSTWO DANYCH / Szyfrowany system i różne poziomy dostępu oparte na rolach",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
          "Wizytówka i indywidualny profil restauracji na portalu klienta i w aplikacji mobilnej",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.2":
          "Dane opisowe restauracji",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.3":
          "Materiały graficzne",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.4":
          "Zarządzanie godzinami dostępności",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.5":
          "Przerwy i dni wyjątkowe",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.6":
          "Parametryzacja przyjmowania rezerwacji",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.7":
          "Konfiguracja sali i stolików",
        "forRestaurantsPageAllOffers.listHeader.4.listItem.1":
          "Portal zarządzania dla restauracji",
        "forRestaurantsPageAllOffers.listHeader.4.listItem.2":
          "Aplikacja dla restauracji na tablet",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.1":
          "Help desk i wsparcie techniczne",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
          "Szkolenie wdrożeniowe dla załogi restauracji",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
          "Obecność / promocja restauracji na kanałach social media MojStolik.pl",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
          "Liczba wiadomości SMS dostępnych w abonamencie",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light":
          "100 SMS/mc",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced":
          "300 SMS/mc",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro":
          "600 SMS/mc",
        /* --forRestaurant page 2.0 - formularz-- */
        "forRestaurantsPageForm.downloadButton": "POBIERZ DANE",
        "forRestaurantsPageForm.header":
          "Tutaj rozpoczynasz współpracę z mojstolik.pl",
        "forRestaurantPageForm.apiError":
          "Błąd pobierania danych oferty. Prosimy spróbować później i przepraszamy za utrudnienia",
        "forRestaurantPageForm.paramsError": "Przepraszamy, nie udało wyświetlić się szczegółów oferty. Aby zapoznać się z naszymi ofertami przejdź do",
        "forRestaurantPageForm.paramsError.link": "strony ofert",
        "forRestaurantsPageForm.chosenPlan": "Wybrany plan MOJSTOLIK.PL",
        "forRestaurantsPageForm.discountCode": "Promocja: ",
        "forRestaurantsPageForm.payment.yearly":
          "Wybrany plan jest rozliczany z rocznym okresem.",
        "forRestaurantsPageForm.payment.monthly":
          "Wybrany plan jest rozliczany z miesięcznym okresem.",
        "forRestaurantsPageForm.additionalOptions": "Wybrane opcje dodatkowe:",
        "forRestaurantsPageForm.formHeader.1":
          "Podaj podstawowe dane restauracji",
        "forRestaurantsPageForm.formHeader.2": "Podaj dane osoby kontaktowej",
        "forRestaurantsPageForm.formHeader.3": "Podaj dane do Umowy",
        "forRestaurantsPageForm.formHeader.4":
          "Podaj dane osoby reprezentującej podmiot",
        "forRestaurantsPageForm.formHeader.5": "Umowa MOJSTOLIK.PL",
        "forRestaurantsPageForm.restaurantName": "Nazwa restauracji",
        "forRestaurantsPageForm.street": "Ulica",
        "forRestaurantsPageForm.apartment": "Lokal",
        "forRestaurantsPageForm.postalCode": "Kod pocztowy",
        "forRestaurantsPageForm.city": "Miejscowość",
        "forRestaurantsPageForm.email": "Adres e-mail",
        "forRestaurantsPageForm.phoneNumber": "Numer telefonu",
        "forRestaurantsPageForm.website": "Adres strony www",
        "forRestaurantsPageForm.website.menu":
          "Adres strony www z menu restauracji",
        "forRestaurantsPageForm.name": "Imię",
        "forRestaurantsPageForm.surname": "Nazwisko",
        "forRestaurantsPageForm.title": "Tytuł",
        "forRestaurantsPageForm.entity": "Nazwa podmiotu",
        "forRestaurantsPageForm.email.invoice": "Adres e-mail do faktur",
        "forRestaurantsPageForm.email.contact": "Adres e-mail kontaktowy",
        "forRestaurantsPageForm.proxy": "Typ pełnomocnictwa",
        "forRestaurantsPageForm.select.owner": "Właściciel",
        "forRestaurantsPageForm.select.manager": "Menedżer",
        "forRestaurantsPageForm.select.boardMember": "Członek zarządu",
        "forRestaurantsPageForm.select.other": "Inny",
        "forRestaurantsPageForm.contract": "Treść umowy",
        "forRestaurantsPageForm.pdfLink": "Pobierz pdf",
        "forRestaurantsPageForm.statement.1":
          "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl",
        "forRestaurantsPageForm.statement.2":
          "Akceptuję warunki powyższej umowy oraz regulaminu MOJSTOLIK.PL i oświadczam, że jestem upoważniony do zawaria umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl w imieniu restauracji.",
        "forRestaurantsPageForm.loading": "Twój formularz jest wysyłany...",
        "forRestaurantsPageForm.success": "Wysyłanie formularza się powiodło",
        "forRestaurantsPageForm.nipError": "Wpisz poprawny numer NIP",
        "forRestaurantsPageForm.loadingData": "Trwa pobieranie danych...",
        "forRestaurantsPageForm.error": "Wystąpił błąd.",
        "forRestaurantsPageForm.dataSuccess": "Dane pobrane prawidłowo",
        "forRestaurantsPageForm.generatingContract":
          "Trwa generowanie umowy...",
        /* --forRestaurant page 2.0 - sukces-- */
        "forRestaurantsPageSuccess.header":
          "Dziękujemy, że wybrałeś ofertę Mojstolik.pl",
        "forRestaurantsPageSuccess.text.1":
          "ZA CHWILĘ NA PODANY ADRES E-MAIL ZOSTANIE WYSŁANY DOKUMENT UMOWY, KTÓRA ZOSTAŁA ZAAKCEPTOWANA W POPRZEDNIM KROKU.",
        "forRestaurantsPageSuccess.text.2":
          "RÓWNOLEGLE PRZYGOTUJEMY I PRZEKAŻEMY CI NIEZBĘDNE INSTRUKCJE, KTÓRE UMOŻLIWIĄ CI SKONFIGUROWANIE TWOJEJ RESTAURACJI W SYSTEMIE i ROZPOCZĘCIE WSPÓŁPRACY.",
        "forRestaurantsPageSuccess.text.3": "do zobaczenia!",
        "forRestaurantsPageSuccess.button": "Wróć na stronę główną",

        /* -- global -- */
        "global.messages.sessionTimeout": "Sesja wygasła",
        "global.form.message.requiredField": "To pole jest wymagane",
        "global.form.message.vatIDNumber": "NIP powinien zawerać 10 cyfr",
        "global.form.message.invalidEmailFormat":
          "Nieprawidłowy format adresu e-mail",
        "global.form.message.invalidPhoneFormat":
          "Nieprawidłowy format numeru telefonu",
        "global.form.message.invalidBirthDateFormat":
          "Nieprawidłowy format daty urodzenia",
        "global.form.message.invalidPostCodeFormat":
          "Nieprawidłowy format kodu pocztowego. Oczekiwany XX-XXX",
        "global.form.message.invalidNipFormat":
          "Nieprawidłowy format numeru NIP",
        "global.form.message.invalidKrsFormat":
          "Nieprawidłowy format numeru KRS",
        "global.form.message.invalidRegonFormat":
          "Nieprawidłowy format numeru REGON",
        "global.form.message.invalidUrlFormat":
          "Nieprawidłowy format adresu WWW",
        "global.form.message.newPasswordsDoNotMatch": "Nowe hasła różnią się!",
        "global.form.message.atLeastNineCharacters":
          "Wymagane co najmniej dziewięć znaków",
        "global.time.minutes": "minut",
        /* -- metadata -- */
        "aboutPage.metadata.title": "O nas",
        "aboutPage.metadata.description": "o nas opis",
        "aboutPage.metadata.keywords": "o nas slowa kluczowe",
        "accountPage.metadata.title": "Twoje konto",
        "accountPage.metadata.description": "Twoje konto opis",
        "accountPage.metadata.keywords": "Twoje konto słowa kluczowe",
        "contactPage.metadata.title": "Kontakt",
        "contactPage.metadata.description": "Kontakt opis",
        "contactPage.metadata.keywords": "Kontakt słowa kluczowe",
        "documentsPage.metadata.title": "Dokumenty",
        "documentsPage.metadata.description": "Dokumenty opis",
        "documentsPage.metadata.keywords": "Dokumenty słowa kluczowe",
        "forGuestPage.metadata.title": "Dla gościa",
        "forGuestPage.metadata.description": "Dla gościa opis",
        "forGuestPage.metadata.keywords": "Dla gościa słowa kluczowe",
        "howItWorksPage.metadata.title": "Jak to działa?",
        "howItWorksPage.metadata.description": "Jak to działa opis",
        "howItWorksPage.metadata.keywords": "Jak to działa słowa kluczowe",
        "loginPage.metadata.title": "Logowanie",
        "loginPage.metadata.description": "Logowanie opis",
        "loginPage.metadata.keywords": "Logowanie słowa kluczowe",
        "resetPasswordPage.metadata.title": "Zresetuj hasło",
        "resetPasswordPage.metadata.description": "Zresetuj hasło opis",
        "resetPasswordPage.metadata.keywords": "Zresetuj hasło słowa kluczowe",
        "logoutPage.metadata.title": "Wylogowanie",
        "logoutPage.metadata.description": "Wylogowanie opis",
        "logoutPage.metadata.keywords": "Wylogowanie słowa kluczowe",
        "notFoundPage.metadata.title": "404 - Strona nieznaleziona!",
        "notFoundPage.metadata.description": "404 - Strona nieznaleziona opis",
        "notFoundPage.metadata.keywords": "",
        "priceListPage.metadata.title": "Cennik",
        "priceListPage.metadata.description": "Cennik opis",
        "priceListPage.metadata.keywords": "Cennik słowa kluczowe",
        "registrationPage.metadata.title": "Rejestracja",
        "registrationPage.metadata.description": "Rejestracja opis",
        "registrationPage.metadata.keywords": "Rejestracja słowa kluczowe",
        "reservationHistoryPage.metadata.title": "Twoje rezerwacje",
        "reservationHistoryPage.metadata.description": "Twoje rezerwacje opis",
        "reservationHistoryPage.metadata.keywords":
          "Twoje rezerwacje słowa kluczowe",
        "searchMapPage.metadata.title": "Wyniki wyszukiwania",
        "searchMapPage.metadata.description": "Wyniki wyszukiwania opis",
        "searchMapPage.metadata.keywords": "Wyniki wyszukiwania słowa kluczowe",
        "searchPage.metadata.title": "Wyniki wyszukiwania",
        "searchPage.metadata.description": "Wyniki wyszukiwania opis",
        "searchPage.metadata.keywords": "Wyniki wyszukiwania słowa kluczowe",
        "privacyPolicyPage.metadata.title": "Polityka prywatności",
        "privacyPolicyPage.metadata.description": "",
        "privacyPolicyPage.metadata.keywords": "",
        "cookiesPolicyPage.metadata.title": "Polityka Cookies",
        "cookiesPolicyPage.metadata.description": "",
        "cookiesPolicyPage.metadata.keywords": "",
        /* -- shortcuts -- */
        "shortcut.isClosed": "nieczynne",
        "shortcut.monday": "Pon.",
        "shortcut.tuesday": "Wt.",
        "shortcut.wednesday": "Śr.",
        "shortcut.thursday": "Czw.",
        "shortcut.friday": "Pt.",
        "shortcut.saturday": "Sob.",
        "shortcut.sunday": "Niedz.",
        /* -- default values -- */
        "defaultValues.restaurant": "Restauracja",
        /* -- restaurantPromotionInfoModal component -- */
        "restaurantPromotionInfoModal.button.use": "Skorzystaj",
        "restaurantPromotionInfoModal.paragraph.promotionDetails":
          "Szczegóły oferty",
        /* -- previewImageModal component -- */
        "previewImageModal.component.anchor.close": "Zamknij",
        /* -- previewRestaurantModal component -- */
        "previewRestaurantModal.component.anchor.close": "Zamknij",
        /* -- deleteAccountModal component -- */
        "deleteAccountModal.component.header": "Usuń konto",
        "deleteAccountModal.component.paragraph.deleteAccountDescription":
          "Czy na pewno chcesz skasować swoje konto? Historia Twoich rezerwacji również zostanie usunięta.",
        "deleteAccountModal.component.button.confirm": "Zatwierdź",
        "deleteAccountModal.component.button.cancel": "Anuluj",
        /* -- cancellationReservationModal component -- */
        "cancellationReservationModal.component.header": "Odwołanie rezerwacji",
        "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
          "Czy na pewno chcesz odwołać rezerwację stolika w restauracji {{restaurantName}} o godz. {{reservationHour}} w dniu {{reservationDate}}?",
        "cancellationReservationModal.component.paragraph.cancellationPaidReservationDescription":
          "Opłata związana rezerwacją została przekazana restauracji. W celu ewentualnego odzyskania środków prosimy o skontaktowanie się bezpośrednio z restauracją.",
        "cancellationReservationModal.component.button.confirm": "Zatwierdź",
        "cancellationReservationModal.component.button.cancel": "Anuluj",
        /* -- searchResultItem component -- */
        "searchResultItem.component.label.availableHours": "Dostępne godziny",
        "searchResultItem.component.label.futureReservationDaysError":
          "Rezerwacje zdalne są możliwe na {{days}} dni do przodu",
        "searchResultItem.component.label.noAvailableTables":
          "Dla wybranych parametrów wyszukiwania (data, godzina, liczba osób) ta restauracja nie oferuje stolików do rezerwacji.",
        "searchResultItem.component.button.book": "Zarezerwuj",
        "searchResultItem.component.button.cancel": "Anuluj",
        "searchResultItem.component.button.see": "Zobacz",
        /* -- passwordFormModal component -- */
        "passwordFormModal.component.header": "Wprowadź hasło do konta",
        "passwordFormModal.component.paragraph.deleteAccountDescription":
          "Wprowadź swoje hasło, aby potwierdzić wykonanie akcji.",
        "passwordFormModal.component.button.confirm": "Zatwierdź",
        "passwordFormModal.component.button.cancel": "Anuluj",
        /* -- cuisine types -- */
        "cuisine.romania": "Kuchnia rumuńska",
        "cuisine.american": "Kuchnia amerykańska",
        "cuisine.arabic": "Kuchnia arabska",
        "cuisine.chinese": "Kuchnia chińska",
        "cuisine.polish": "Kuchnia polska",
        "cusine.chinese": "Kuchnia chińska",
        "cusine.polish": "Kuchnia polska",
        /* -- cookies policy line -- */
        "component.cookiesPolicyLine.text":
          "Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzsz się na ich użycie.",
        "component.cookiesPolicyLine.privacyPolicyText": "Polityka prywatności",
        /* -- header -- */
        "component.header.menu.mainPage": "Rezerwuj",
        "component.header.menu.forRestaurants": "Dla restauracji",
        "component.header.menu.portal.restaurant": "Portal restauracji",
        "component.header.menu.forRestaurants.offer": "Nasza oferta",
        "component.header.menu.blog": "Nowości MOJSTOLIK.PL",
        "component.header.menu.christmas": "OFERTY ŚWIĄTECZNE",
        "component.header.menu.forGuest": "Dla gościa",
        "component.header.menu.howItWorks": "Jak to działa?",
        "component.header.menu.priceList": "Cennik",
        "component.header.menu.contact": "Kontakt",
        "component.header.menu.documents": "Dokumenty",
        "component.header.menu.about": "O nas",
        "component.header.menu.login": "Zaloguj się",
        "component.header.menu.logout": "Wyloguj się",
        "component.header.menu.reservationHistory": "Twoje rezerwacje",
        "component.header.menu.yourAccount": "Twoje konto",
        "component.header.menu.covid": "Poradniki COVID-19",
        "component.header.menu.covid.restaurant": "Poradnik dla restauracji",
        "component.header.menu.covid.client": "Poradnik dla gości",
        "component.header.menuMobile.covid.restaurant":
          "Poradnik COVID-19 dla restauracji",
        "component.header.menuMobile.covid.client":
          "Poradnik COVID-19 dla gości",
        "component.header.menu.events": "Wydarzenia",
        "component.header.menu.event": "Wydarzenie - ",
        /* -- footer -- */
        "component.footer.menu.header.forClients": "O MOJSTOLIK.pl",
        "component.footer.menu.header.informations": "Informacje",
        "component.footer.menu.header.yourAccount": "Twoje konto",
        "component.footer.menu.header.socialMedia": "Social media",
        "component.footer.menu.header.applicationDownload": "Pobierz aplikację",
        "component.footer.menu.forRestaurants": "Dla restauracji",
        "component.footer.menu.forGuest": "Dla gościa",
        "component.footer.menu.howItWorks": "Jak to działa?",
        "component.footer.menu.priceList": "Cennik",
        "component.footer.menu.contact": "Kontakt",
        "component.footer.menu.documents": "Dokumenty",
        "component.footer.menu.about": "O nas",
        "component.footer.menu.login": "Zaloguj się",
        "component.footer.menu.reservationHistory": "Twoje rezerwacje",
        "component.footer.menu.yourAccount": "Twoje konto",
        "component.footer.menu.logout": "Wyloguj się",
        "component.footer.menu.registration": "Rejestracja",
        "component.footer.menu.privacyPolicy": "Polityka prywatności",
        "component.footer.menu.cookiesPolicy": "Polityka cookies",
        /* -- index page -- */
        "indexPage.background.header":
          "Znajdź najlepsze restauracje w Twoim mieście,",
        "indexPage.header.currentPromotions": "Oferty specjalne i wydarzenia",
        "indexPage.header.currentPromotionsExtend": "w Twojej lokalizacji",
        "indexPage.header.blogs": "Wyróżnione artykuły",
        "indexPage.header.mostPopular": "Najbardziej popularne",
        "indexPage.header.mostPopularExtend": "w Twojej lokalizacji",
        "indexPage.background.headerExtend": "a następnie zarezerwuj stolik:",
        "indexPage.input.label.location": "Lokalizacja",
        "indexPage.input.label.date": "Data",
        "indexPage.input.label.hour": "Godzina",
        "indexPage.input.label.persons": "Liczba osób",
        "indexPage.input.label.persons.optionExtend": "os.",
        "indexPage.input.label.restaurantType": "Rodzaj kuchni",
        "indexPage.input.label.rating": "Ocena",
        "indexPage.input.label.rating.optionExtend": "gwiazd.",
        "indexPage.input.label.priceLevel": "Poziom cenowy",
        "indexPage.input.label.searchByName": "Wyszukaj po nazwie",
        "indexPage.input.placeholder.select": "Wybierz",
        "indexPage.input.placeholder.type": "Wpisz",
        "indexPage.input.placeholer.typeInOrSelect": "Wpisz lub wybierz",
        "indexPage.input.noData": "Brak wyników.",
        "indexPage.button.search": "Szukaj",
        "indexPage.button.showAdditionalFilters": "Dodatkowe filtry",
        "indexPage.button.hideAdditionalFilters": "Ukryj dodatkowe filtry",
        "indexPage.button.use": "Skorzystaj",
        "indexPage.button.promotion": "promocja",
        "indexPage.button.bookNow": "Zarezerwuj",
        "indexPage.button.readMore": "Czytaj",
        /* -- account page -- */
        "accountPage.header": "Twoje konto",
        "accountPage.subheader":
          "Zmień swoje dane osobiste, aby proces Twojej realizacji przebiegał szybciej.",
        "accountPage.headerInColumn.yourPersonalData": "Twoje dane",
        "accountPage.headerInColumn.yourPassword": "Twoje hasło",
        "accountPage.input.label.firstName": "Imię",
        "accountPage.input.placeholder.firstName": "Np. Jan",
        "accountPage.input.label.lastName": "Nazwisko",
        "accountPage.input.placeholder.lastName": "Np. Kowalski",
        "accountPage.input.label.phoneNumber": "Numer telefonu",
        "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
        "accountPage.input.label.email": "Adres e-mail",
        "accountPage.input.placeholder.email": "Np. adres@email.pl",
        "accountPage.paragraph.optionalFields": "Pola opcjonalne",
        "accountPage.input.label.birthDate": "Data urodzenia",
        "accountPage.input.placeholder.birthDate": "Np. 1976-10-25",
        "accountPage.input.label.postalCode": "Kod pocztowy",
        "accountPage.input.placeholder.postalCode": "Np. 03-123",
        "accountPage.input.label.city": "Miasto",
        "accountPage.input.placeholder.city": "Np. Warszawa",
        "accountPage.input.label.streetAndLocalNumber":
          "Ulica i numer mieszkania",
        "accountPage.input.placeholder.streetAndLocalNumber":
          "Np. Grójecka 130/105",
        "accountPage.button.save": "Zapisz",
        "accountPage.button.deleteAccount": "Usuń konto",
        "accountPage.input.label.oldPassword": "Stare hasło",
        "accountPage.input.label.newPassword": "Nowe hasło",
        "accountPage.input.label.newPasswordRepeat": "Nowe hasło (powtórz)",
        "accountPage.button.changePassword": "Zmień hasło",
        "accountPage.message.personalDataUpdated":
          "Dane osobowe zostały zaktualizowane.",
        "accountPage.message.accountPermanentlyDeleted":
          "Twoje konto zostało permanentnie skasowane.",
        "accountPage.message.passwordChanged": "Hasło zostało zmienione.",
        "accountPage.label.changePasswordNotPossible":
          "Zmiana hasła jest niemożliwa, gdyż zalogowałeś się przez Facebook'a lub Google'a.",
        /* -- reservation history page -- */
        "reservationHistoryPage.sendMessage": "Wyślij wiadomość",
        "reservationHistoryPage.send": "Wyślij",
        "reservationHistoryPage.cancel": "Anuluj",
        "reservationChatPage.send": "Wyślij",
        "reservationChatPage.writeMessage": "Wpisz wiadomość",
        "reservationHistoryPage.header": "Twoje rezerwacje",
        "reservationHistoryPage.subheader":
          "W tym miejscu możesz przejrzeć swoje historyczne rezerwacje oraz zarządzać przyszłymi rezerwacjami.",
        "reservationHistoryPage.paragraph.personsShortcut": "os.",
        "reservationHistoryPage.button.revoke": "Odwołaj",
        "reservationHistoryPage.button.pay": "Zapłać",
        "reservationHistoryPage.paragraph.additionalDescription":
          "Komunikacja z restauracją",
        "reservationHistoryPage.paragraph.date": "Data",
        "reservationHistoryPage.paragraph.duration": "Czas trwania",
        "reservationHistoryPage.paragraph.hour": "Godzina",
        "reservationHistoryPage.paragraph.persons": "Liczba osób",
        "reservationHistoryPage.paragraph.reservationIsEmpty":
          "Rezerwacja nie istnieje",
        "reservationHistoryPage.paragraph.reservationListIsEmpty":
          "Lista rezerwacji jest pusta.",
        "reservationHistoryPage.paragraph.status": "Status",
        "reservationPage.paragraph.lateInfo":
          "Uwaga, w przypadku spóźnienia stolik czeka na Gościa 15 minut!",
        "reservationHistoryPage.paragraph.payment.status": "Status płatności",
        /* -- search page -- */
        "searchPage.header": "Wyniki wyszukiwania",
        "searchPage.button.map": "Mapa",
        "searchPage.button.list": "Lista",
        "searchPage.paragraph.noData": "Brak wyników dla podanych kryteriów.",
        /* -- search map page -- */
        "searchMapPage.map.button.refresh": "Odśwież mapę",
        "searchMapPage.header": "Wyniki wyszukiwania",
        "searchMapPage.button.map": "Mapa",
        "searchMapPage.button.list": "Lista",
        /* -- login page -- */
        "loginPage.header": "Logowanie",
        "loginPage.subheader":
          "Będąc zalogowanym użytkownikiem możesz szybciej rezerwować stoliki oraz przeglądać historię swoich rezerwacji.",
        "loginPage.columns.header.standardLogin": "Logowanie standardowe",
        "loginPage.columns.header.socialLogin": "Zaloguj się za pomocą",
        "loginPage.input.label.phoneOrEmail": "Numer telefonu",
        "loginPage.input.placeholder.phoneOrEmail": "",
        "loginPage.input.label.password": "Hasło",
        "loginPage.input.placeholder.password": "",
        "loginPage.anchor.passwordReset": "Zresetuj hasło",
        "loginPage.button.logIn": "Zaloguj się",
        "loginPage.paragraph.dontHaveAccountYet": "Nie masz jeszcze konta?",
        "modal.login.dontHaveAccountYet": "Nie masz jeszcze konta",
        "loginPage.paragraph.registerNow": "Zarejestruj się!",
        "loginPage.button.register": "Zarejestruj się",
        "loginPage.message.logged": "Zalogowano.",
        "loginPage.message.loggedViaFacebook": "Zalogowano przez Facebook'a.",
        "loginPage.message.loggedViaApple": "Zalogowano przez Apple.",
        "loginPage.message.loggedViaGoogle": "Zalogowano przez Google'a.",
        "loginPage.paragraph.alreadyLoggedIn": "Jesteś aktualnie zalogowany.",
        /* -- Reset password page -- */
        "resetPasswordPage.header": "Zresetuj hasło",
        "resetPasswordPage.subheader":
          "Wypełnij poniższy formularz, a my wyślemy Ci SMS-em nowe hasło do konta",
        "resetPasswordPage.subheader2":
          "Wyślemy ci nowe hasło na Twój numer telefonu",
        "resetPasswordPage.input.label.phoneOrEmail": "Numer telefonu",
        "resetPasswordPage.input.placeholder.phoneOrEmail": "",
        "resetPasswordPage.button.confirm": "Potwierdź",
        "resetPasswordPage.toast.passwordChanged":
          "Nowe hasło zostało wysłane SMSem na podany podczas rejestracji nr telefonu.",
        "resetPasswordPage.toast.error.passwordChanged":
          "Podany nr telefonu jest błędny.",
        /* -- cookies policy page -- */
        "cookiesPolicyPage.header": "Polityka Cookies",
        "cookiesPolicyPage.subheader": "Istotne informacje.",
        "cookiesPolicyPage.point.1":
          "Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.",
        "cookiesPolicyPage.point.2":
          "Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.",
        "cookiesPolicyPage.point.3":
          "Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest właściciel mojstolik.pl spółka Table4Rest Sp. z o.o. z siedzibą pod adresem ul. Ksawerów 21, 02-656 Warszawa.",
        "cookiesPolicyPage.point.4": "Pliki cookies wykorzystywane są w celu:",
        "cookiesPolicyPage.point.4a":
          "dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;",
        "cookiesPolicyPage.point.4b":
          "tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;",
        "cookiesPolicyPage.point.4c":
          "utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;",
        "cookiesPolicyPage.point.5":
          "W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.",
        "cookiesPolicyPage.point.6":
          "W ramach Serwisu stosowane są następujące rodzaje plików cookies:",
        "cookiesPolicyPage.point.6a":
          "„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;",
        "cookiesPolicyPage.point.6b":
          "pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;",
        "cookiesPolicyPage.point.6c":
          "wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;",
        "cookiesPolicyPage.point.6d":
          "„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;",
        "cookiesPolicyPage.point.6e":
          "„reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.",
        "cookiesPolicyPage.point.7":
          "W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).",
        "cookiesPolicyPage.point.8":
          "Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.",
        /* -- privacy policy page -- */
        "privacyPolicyPage.header": "Polityka prywatności",
        "privacyPolicyPage.subheader": "Istotne informacje.",
        "privacyPolicyPage.point.one": "POSTANOWIENIA OGÓLNE",
        "privacyPolicyPage.point.one.1":
          "Administratorem danych jest Table4Rest Sp. z o.o. z siedzibą w Warszawa 02-656, ul. Ksawerów 21, wpisanej do Rejestru Przedsiębiorców pod numerem KRS: 0000752110 dla której akta rejestrowe prowadzi SĄD REJONOWY DLA M.ST.WARSZAWY W WARSZAWIE, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO NIP: 5213842139 REGON: 381529521",
        "privacyPolicyPage.point.one.2":
          "Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak jak zostało to opisane w Polityce Prywatności (jeżeli wynika to bezpośrednio z opisu).",
        "privacyPolicyPage.point.one.3":
          "Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został określeniem „Ty”, „Administrator” – „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.",
        "privacyPolicyPage.point.one.4":
          "Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).",
        "privacyPolicyPage.point.one.5":
          "Dane osobowe podawane w formularzu na landing page’u są traktowane jako poufne i nie są widoczne dla osób nieuprawnionych.",
        "privacyPolicyPage.point.two": "ADMINISTRATOR DANYCH",
        "privacyPolicyPage.point.two.1":
          "Usługodawca jest administratorem osób które skorzystały z formularza kontaktowego na stronie internetowej lub skorzystały z innej formy komunikacji (poczta elektroniczna, telefon, aplikacja mobilna, wtyczka zamów rozmowę, inne formy komunikacji).",
        "privacyPolicyPage.point.two.2": "Dane osobowe przetwarzane są:",
        "privacyPolicyPage.point.two.2a":
          "a. zgodnie z przepisami dotyczącymi ochrony danych osobowych,",
        "privacyPolicyPage.point.two.2b":
          "b. zgodnie z wdrożoną Polityką Prywatności,",
        "privacyPolicyPage.point.two.2c":
          "c. w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji świadczonych Usług,",
        "privacyPolicyPage.point.two.2d":
          "d. w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą.",
        "privacyPolicyPage.point.two.3":
          "Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego.",
        "privacyPolicyPage.point.two.4":
          "Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji Usługodawcy jest możliwy drogą elektroniczną pod adresem e-mail: kontakt@mojstolik.pl",
        "privacyPolicyPage.point.two.5":
          "Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych.",
        "privacyPolicyPage.point.two.6":
          "Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom ścigania).",
        "privacyPolicyPage.point.two.7":
          "Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.",
        "privacyPolicyPage.point.two.8":
          "Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.",
        "privacyPolicyPage.point.two.9":
          "Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy.",
        /* -- restaurant profile page -- */
        "restaurantPage.button.pageWWW": "Strona WWW",
        "restaurantPage.button.seeMenu": "Zobacz menu",
        "restaurantPage.button.confirm": "Potwierdź",
        "restaurantPage.button.cancel": "Anuluj",
        "restaurantPage.button.choose": "Wybierz",
        "restaurantPage.button.back": "Wróć",
        "restaurantPage.button.showMore": "Pokaż więcej",
        "restaurantPage.button.showMore.promotion": "Więcej",
        "restaurantPage.button.showLess": "Pokaż mniej",
        "restaurantPage.button.goToHomePage": "Powrót do strony głównej",
        "restaurantPage.button.reservationDetails": "Szczegóły rezerwacji",
        "restaurantPage.button.goToPaymentPage": "Przejdź do panelu płatności",
        "restaurantPage.paymentPage.info":
          "Zaraz zostaniesz przekierowany do operatora płatności Przelewy24.pl",
        "restaurantPage.header.restaurantDescription": "Opis restauracji",
        "restaurantPage.header.bookingPolicy": "Polityka rezerwacji",
        "restaurantPage.header.gallery": "Galeria zdjęć",
        "restaurantPage.header.yourReservation": "Twoja rezerwacja",
        "restaurantPage.header.yourPersonalData": "Twoje dane",
        "restaurantPage.header.ctnWithoutLogin": "Kontynuuj bez logowania",
        "restaurantPage.header.login": "Logowanie",
        "restaurantPage.button.login": "Zaloguj się",
        "restaurantPage.button.orLoginWith": "Lub zaloguj się przy użyciu",
        "restaurantPage.header.createAccount":
          "Załóż konto w MojStolik.pl i zyskaj:",
        "restaurantPage.header.createAccount.benefit.1":
          "Szybki sposób rezerwacji",
        "restaurantPage.header.createAccount.benefit.2":
          "Pełną historię rezerwacji",
        "restaurantPage.header.createAccount.benefit.3":
          "Dostęp do unikalnych promocji",
        "restaurantPage.button.createAccount": "Załóż konto MojStolik.pl",
        "restaurantPage.header.statements": "Oświadczenia",
        "restaurantPage.header.statement": "Oświadczenie",
        "restaurantPage.header.promotions": "Oferty specjalne i wydarzenia",
        "restaurantPage.header.use": "Skorzystaj",
        "restaurantPage.header.cancel": "Zrezygnuj",
        "restaurantPage.header.reservationConfirmation":
          "Potwierdzenie rezerwacji",
        "restaurantPage.header.reservationsPersons":
          "Rezerwacje od {{min}} do {{max}} osób",
        "restaurantPage.paragraph.reservationConfirmationDescription":
          "Wpisz kod z wiadomości SMS, którą wysłaliśmy na Twój numer telefonu, aby dokończyć proces rezerwacji stolika",
        "restaurantPage.input.label.code": "Wpisz kod SMS z dnia",
        "restaurantPage.input.placeholder.code": "",
        "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
          "Brak dostępnych stolików przy wybranych kryteriach (data, liczba osób, promocja). Wybierz inną datę, liczbę osób lub promocję.",
        "restaurantPage.paragraph.noAvailableHours":
          "Brak wolnych stolików w wybranych kryteriach.",
        "restaurantPage.paragraph.noAvailableHoursExt":
          "Wybierz inną datę lub liczbę osób.",
        "restaurantPage.input.label.date": "Data",
        "restaurantPage.input.label.hour": "Godzina",
        "restaurantPage.input.label.persons": "Liczba osób",
        "restaurantPage.input.label.persons.optionExtend": "os.",
        "restaurantPage.input.label.firstName": "Imię",
        "restaurantPage.input.label.vatIdNumber": "NIP",
        "restaurantPage.input.label.address": "Adres",
        "restaurantPage.input.label.companyName": "Nazwa firmy",
        "restaurantPage.input.label.street": "Ulica",
        "restaurantPage.input.label.buildingNumber": "Budynek",
        "restaurantPage.input.label.localNumber": "Lokal",
        "restaurantPage.input.label.postalCode": "Kod pocztowy",
        "restaurantPage.input.label.city": "Miasto",
        "restaurantPage.input.label.country": "Kraj",
        "restaurantPage.get.company.data": "Pobierz",
        "restaurantPage.input.label.lastName": "Nazwisko",
        "restaurantPage.input.label.phoneNumber": "Numer telefonu",
        "restaurantPage.input.label.email": "Adres e-mail",
        "restaurantPage.input.label.password": "Wpisz hasło",
        "restaurantPage.input.label.passwordRepeat": "Powtórz hasło",
        "restaurantPage.input.label.description":
          "Uwagi dot. rezerwacji",
        "agreements.allAgreements": "Zaznacz wszystko",
        "agreements.invoice": "Podaj dane do faktury",
        "restaurantPage.input.label.personalData":
          "Oświadczam, że znam i akceptuję postanowienia Regulaminu świadczenia usług przez MojStolik za pośrednictwem Aplikacji dla Klienta. ",
        "restaurantPage.input.label.personalData.extension":
          " Regulamin MojStolik",
        "restaurantPage.input.label.readWithdrawal":
          "Zapoznałem się z regulaminem MojStolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy. ",
        "restaurantPage.input.label.readWithdrawal.extension":
          "Regulamin mojstolik.pl",
        "restaurantPage.input.label.agreementMarketing":
          "Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach MojStolik dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
        "restaurantPage.input.label.agreementMarketing.extension": "",
        "restaurantPage.input.label.marketingAgreement3thPart":
          "Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach Restauracji i Podmiotów Współpracujących wskazanych na stronie mojstolik.pl, dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
        "restaurantPage.input.label.marketingAgreement3thPart.extension": "",
        "agreement.eventAgreement":
          "Zapoznałem się i akceptuję regulamin oferty specjalne znajdujący się",
        "registrationPage.input.label.eventAgreementUrl.extension":
          "pod tym linkiem",
        "restaurantPage.input.placeholder.typeIn": "Wpisz",
        "restaurantPage.input.placeholder.firstName": "Np. Jan",
        "restaurantPage.input.placeholder.lastName": "Np. Kowalski",
        "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
        "restaurantPage.input.placeholder.email": "Np. adres@email.pl",
        "restaurantPage.paragraph.fillOrLogin":
          "Wpisz pozostałe dane lub pobierz za pomocą Facebook, Google lub Apple",
        "restaurantPage.paragraph.doYouWantToRegister":
          "Jeśli chcesz założyć konto w portalu MOJSTOLIK.PL nadaj hasło",
        "restaurantPage.paragraph.dataIsIncorrect": "Dane są niepoprawne?",
        "restaurantPage.paragraph.sendCodeAgainText":
          "Kod został ponownie wysłany SMS-em.",
        "restaurantPage.anchor.sendCodeAgain": "Wyślij kod ponownie",
        "restaurantPage.anchor.edit": "Edytuj",
        "restaurantPage.anchor.login": "Zaloguj",
        "restaurantPage.paragraph.or": " lub ",
        "restaurantPage.anchor.register": "zarejestruj się",
        "restaurantPage.paragraph.dontWontUsePromotion":
          "Nie chcę korzystać z promocji",
        "restaurantPage.messages.codeResent":
          "Kod SMS został ponownie wysłany.",
        "restaurantPage.header.reservationRecieved": "Przyjęcie rezerwacji",
        "restaurantPage.summary.paragraph.firstLastName": "Imię Nazwisko:",
        "restaurantPage.summary.paragraph.phoneNumber": "Numer telefonu:",
        "restaurantPage.summary.paragraph.email": "E-mail:",
        "restaurantPage.summary.paragraph.restName": "Nazwa restauracji:",
        "restaurantPage.summary.paragraph.date": "Termin:",
        "restaurantPage.summary.paragraph.date.time": ", godz.",
        "restaurantPage.summary.paragraph.duration": "Czas trwania:",
        "restaurantPage.summary.paragraph.persons": "Liczba gości:",
        "restaurantPage.summary.paragraph.totalCost": "Suma przedpłat:",
        "restaurantPage.summary.special.offer": "Oferta specjalna:",
        "restaurantPage.summary.paragraph.state": "Status:",
        "restaurantPage.paragraph.tableReserved": "Zarezerwowano stolik",
        "reservation.status.Canceled": "Odwołana",
        "reservation.status.Completed": "Rezerwacja zrealizowana",
        "reservation.status.Confirmed": "Rezerwacja została potwierdzona",
        "reservation.status.Delayed": "Opóźniona",
        "reservation.status.NotCompleted": "Rezerwacja niezrealizowana",
        "reservation.status.Rejected": "Rezerwacja została anulowana",
        "reservation.status.WaitingForApproval":
          "Rezerwacja oczekuje na zatwierdzenie",
        "reservation.status.WaitingForConfirmation": "Oczekująca",
        "restaurantPage.paragraph.tableReservedDescription":
          "Twój stolik został zarezerwowany na godz.",
        "restaurantPage.paragraph.tableReservedDescriptionInDay": "w dniu",
        "restaurantPage.paragraph.tableWaitingForApproval":
          "Rezerwacja oczekuje na zatwierdzenie",
        "restaurantPage.paragraph.tableWaitingForApprovalDescription1":
          "Twoja rezerwacja na godz.",
        "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
          "Jeśli nie otrzymasz potwierdzenia rezerwacji w ciągu kilku godzin, skontaktuj się bezpośrednio z Restauracją",
        "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay":
          "w dniu",
        "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
          "Po zatwierdzeniu rezerwacji na wskazany numer telefonu zostanie przesłana wiadomość z linkiem do płatności. Rezerwacja jest ważna po otrzymaniu potwierdzenia płatności",
        "restaurantPage.paragraph.tableReservedPaymentInformation":
          "Rezerwacja musi zostać opłacona",
        "restaurantPage.input.label.footer":
          "MojStolik korzysta z różnych kanałów komunikacji elektronicznej oraz prowadzi działalności z wykorzystaniem poczty elektronicznej, telefonów, SMS-ów i MMS-ów. Administratorem Twoich danych osobowych jest Table4Rest spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, 02-656 Warszawa ulica Ksawerów 21, KRS 0000752110, NIP 5213842139. Twoje dane osobowe będą przetwarzane w celu kierowania do Ciebie treści marketingowych w kanałach komunikacji elektronicznej. Więcej informacji na temat przetwarzania danych osobowych, w tym przysługujących Ci praw, znajduje się w Polityce Prywatności.",
        /* -- for guests page -- */
        "forGuestPage.header": "Dla gościa",
        "forGuestPage.subheader": "",
        /* -- how it works page -- */
        "howItWorksPage.header": "Jak to działa?",
        "howItWorksPage.subheader": "Krok po kroku",
        "howItWorksPage.content.title": "Rezerwacja z aplikacji mobilnej",
        "howItWorksPage.content.step1": "Krok 1",
        "howItWorksPage.content.step1.content":
          "Klient w aplikacji mobilnej wyszukuje restauracje i ",
        "howItWorksPage.content.step1.content.strong":
          "w czasie rzeczywistym widzi dostępne stoliki.",
        "howItWorksPage.content.step2": "Krok 2",
        "howItWorksPage.content.step2.content.strong":
          "Manager w swoim panelu widzi zgłoszenie rezerwacji",
        "howItWorksPage.content.step2.content":
          " Wie, czy zgłoszenie przyszło od nowego klienta, czy stałego i czy są jakieś preferencje co do stolika.",
        "howItWorksPage.content.step3": "Krok 3",
        "howItWorksPage.content.step3.content":
          "Klient przychodzi do restauracji gdzie ",
        "howItWorksPage.content.step3.content.strong":
          "jest witany po imieniu. Obsługa ma pełne dane klienta",
        "howItWorksPage.content.step3.contentb":
          " — wie, czy jest ich stałym gościem, czy odwiedza ich po raz pierwszy.",
        "howItWorksPage.phone.title": "Rezerwacja telefoniczna",
        "howItWorksPage.content.phone.step1": "Krok 1",
        "howItWorksPage.content.phone.step1.content1": "Klient ",
        "howItWorksPage.content.phone.step1.content.strong":
          "dzwoni do wybranej restauracji ",
        "howItWorksPage.content.phone.step1.content2":
          "z zapytaniem o rezerwację.",
        "howItWorksPage.content.phone.step2": "Krok 2",
        "howItWorksPage.content.phone.step2.content.strong":
          "Manager w swoim panelu widzi dostępność stolików w czasie rzeczywistym",
        "howItWorksPage.content.phone.step2.content":
          ". Manager wprowadzając numer telefonu klienta, od razu widzi jego dane, bądź uzupełnia/wprowadza dane nowego klienta.",
        "howItWorksPage.content.phone.step3": "Krok 3",
        "howItWorksPage.content.phone.step3.content":
          "Klient przychodzi do restauracji gdzie ",
        "howItWorksPage.content.phone.step3.content.strong":
          "obsługa wita go po imieniu, ma pełne dane Klienta",
        "howItWorksPage.content.phone.step3.contentb":
          " — wie, czy jest ich stałym gościem, czy odwiedza ich po raz pierwszy.",
        /* -- price list page -- */
        "priceListPage.header": "Cennik",
        "priceListPage.subheader": "",
        "priceListPage.content.link.1":
          "W celu otrzymania informacji cenowej prosimy o",
        "priceListPage.content.link.2": "kontakt",
        /* -- contact page -- */
        "contactPage.header": "Kontakt",
        "contactPage.subheader": "Napisz do nas!",
        "contactPage.content.title": "Dane kontaktowe",
        /* -- documents page -- */
        "documentsPage.header": "Dokumenty",
        "documentsPage.header.for.clients": "Dla klienta",
        "documentsPage.header.for.restaurants": "Dla Restauracji",
        "documentsPage.subheader": "Istotne informacje",
        "documentsPage.for.clients.document.1":
          "Regulamin świadczenia usług przez MojStolik za pośrednictwem Aplikacji dla Klienta",
        "documentsPage.for.clients.document.2":
          "Informacja dotycząca przetwarzania danych osobowych",
        "documentsPage.header.for.restaurants.document.1":
          "Regulamin świadczenia usługi udostępnienia Aplikacji MojStolik dla Restauracji",
        "documentsPage.header.for.restaurants.document.2":
          "Umowa powierzenia przetwarzania danych osobowych Restauracji",
        "documentsPage.header.for.restaurants.document.3":
          "Dane osobowe mojstolik.pl",

        /* -- about page -- */
        "aboutPage.header": "O nas",
        "aboutPage.subheader": "",
        "aboutPage.applicationName": "Mojstolik.pl",
        "aboutPage.application.description":
          " – to polski system stworzony przez grupę przyjaciół — tajemniczych miłośników dobrego jedzenia i sztuki goszczenia w restauracjach. To technologiczne rozwiązanie, które pomaga budować społeczność wokół miejsc atrakcyjnych przez dobrą kuchnię oraz szacunek dla czasu i przyzwyczajeń swoich lojalnych gości. MojStolik.pl to coraz większa grupa restauracji, które wspólnymi siłami robią wszystko, aby polska gastronomia była utożsamiana z doskonałą jakością, dbałością o detal i szacunkiem dla fanów spędzania czasu przy stole z przyjaciółmi, rodziną, czy partnerami w biznesie. Założyciele systemu przełożyli swoją restauracyjną pasję na język technologiczny i dzięki temu powstał gastronomiczny portal i aplikacja, które łączą ludzi przy stołach najbardziej cenionych i popularnych restauracji w Polsce.",
        "aboutPage.application.header1":
          " Chcesz zarezerwować stolik w restauracji? Teraz zrobisz to za pomocą aplikacji w telefonie bądź z portalu skupiającego wiele restauracji",
        "aboutPage.application.description1":
          "Mojstolik.pl to nowa kompleksowa platforma rezerwacyjna od niedawna dostępna dla warszawskich restauracji. Pozwala lokalom gastronomicznym na wygodne zarządzanie rezerwacjami stolików w czasie rzeczywistym i integruje wszystkie kanały komunikacji z gośćmi: telefon, email, aplikację mobilną MojStolik i tzw. walk-in. Dzięki niej menedżerowie restauracji mają dostęp do dynamicznego widoku sali, sami decydują o układzie sal, liczbie dostępnych miejsc i na jak długo każdy stolik może być zarezerwowany. Dodatkowym atutem systemu jest mechanizm potwierdzeń SMS o rezerwacji z opcją odwołania poprzez link oraz wysyłania SMS do gości z przypomnieniem o wizycie.",
        "aboutPage.application.header2":
          "MojStolik.pl: tablet zamiast książki rezerwacji",
        "aboutPage.application.description2":
          "MojStolik.pl to także narzędzie wspomagające restauracje w budowaniu lojalności klientów. System umożliwia budowanie bazy danych gości, analizowanie historii rezerwacji a licznik wizyt pokazuje, który raz dana osoba odwiedza lokal. Goście podczas dokonywania rezerwacji mają możliwość dopisania uwag takich jak preferencje dotyczące stolika, ulubionych dań czy win. Nowa platforma pozwala na bezpieczne przechowywanie danych klienta zgodnie z wymaganiami RODO, czego nie spełnia papierowa książka rezerwacji używana przez restauracje.",
        "aboutPage.application.description2b":
          "Z aplikacji korzysta ponad 100 restauracji w całej Polsce jak: Stary Dom, Restauracja Baczewskich, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE’S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari, a wkrótce dołączą do nich kolejne.",
        "aboutPage.application.header3":
          "MojStolik – nowa aplikacja do rezerwacji stolików",
        "aboutPage.application.description3":
          " MojStolik to nowa aplikacja mobilna, dzięki której szybko i wygodnie zarezerwujemy stolik w restauracji. Aplikacja umożliwia natychmiastowe sprawdzenie dostępności i zarezerwowanie stolika na daną godzinę, ponieważ komunikuje się z restauracją w czasie rzeczywistym. Do rezerwacji możemy dodać specjalną prośbę czy komentarz odnośnie do ulubionego stolika w lokalu, informacje o alergiach czy innych preferencjach, a restauracja za pomocą chatu może nam odpowiedzieć czy dana prośba jest możliwa do spełnienia. W aplikacji MojStolik sprawdzimy także aktualne menu lokalu, mamy też dostęp do specjalnych promocji, które przygotowały dla nas restauracje.",
        "aboutPage.contact.data": "Dane kontaktowe",
        "aboutPage.contact.street": "ul.",
        /* -- registration page -- */
        "registrationPage.header": "Rejestracja",
        "registrationPage.subheader":
          "Będąc zalogowanym użytkownikiem możesz szybciej rezerwować stoliki oraz przeglądać historię swoich rezerwacji.",
        "registrationPage.columns.header.registerStandard":
          "Standardowa rejestracja",
        "registrationPage.columns.header.registerWith":
          "Zarejestruj się za pomocą",
        "registrationPage.input.label.firstName": "Imię",
        "registrationPage.input.placeholder.firstName": "Np. Jan",
        "registrationPage.input.label.lastName": "Nazwisko",
        "registrationPage.input.placeholder.lastName": "Np. Kowalski",
        "registrationPage.input.label.phoneNumber": "Numer telefonu",
        "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
        "registrationPage.input.label.email": "Adres e-mail",
        "registrationPage.input.placeholder.email": "Np. adres@email.pl",
        "registrationPage.paragraph.optionalFields": "Pola opcjonalne",
        "registrationPage.input.label.birthDate": "Data urodzenia",
        "registrationPage.input.placeholder.birthDate": "Np. 1976-10-25",
        "registrationPage.input.label.postalCode": "Kod pocztowy",
        "registrationPage.input.placeholder.postalCode": "Np. 03-123",
        "registrationPage.input.label.city": "Miasto",
        "registrationPage.input.placeholder.city": "Np. Warszawa",
        "registrationPage.input.label.streetAndLocalNumber":
          "Ulica i numer mieszkania",
        "registrationPage.input.placeholder.streetAndLocalNumber":
          "Np. Grójecka 130/105",
        "registrationPage.input.label.password": "Hasło",
        "registrationPage.input.placeholder.password": "",
        "registrationPage.input.label.passwordRepeat": "Powtórz hasło",
        "registrationPage.input.placeholder.passwordRepeat": "",
        "registrationPage.header.statements": "Oświadczenia",
        "registrationPage.input.label.agreementPersonalData":
          "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. oraz wybrane przeze mnie restauracje i lokale gastronomiczne w celu wykonania przedmiotu umowy zgodnie z informacjami zawartymi w dokumencie. ",
        "registrationPage.input.label.agreementPersonalData.extension":
          "Informacje dotyczące przetwarzanie danych osobowych Klientów",
        "registrationPage.input.label.agreementReadWithdrawal":
          "Zapoznałem się z regulaminem MojStolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy. ",
        "registrationPage.input.label.agreementReadWithdrawal.extension":
          "Regulamin mojstolik.pl",
        "registrationPage.input.label.agreementMarketing": "Zgoda marketingowa",
        "registrationPage.input.label.agreementMarketing.extension": "Zgoda",
        "registrationPage.input.label.promotionReadWithdrawal.extension":
          "Regulamin Promocji",
        "registrationPage.input.label.agreementThirdPartDataUsage":
          "Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji",
        "registrationPage.input.label.agreementThirdPartDataUsage.extension":
          "pod tym linkiem",
        "registrationPage.button.register": "Zarejestruj się",
        "registrationPage.message.sendCodeAgain":
          "Kod SMS został ponownie wysłany.",
        "registrationPage.message.accountCreated": "Konto zostało utworzone.",
        "registrationPage.message.registrationTimeExpired":
          "Czas rejestracji upłynął",
        "registrationPage.message.enterCodeFromSMS": "Przepisz kod z SMS-a.",
        "registrationPage.paragraph.timeIsOver": "Czas się skończył.",
        "registrationPage.paragraph.timeLeft": "Pozostało",
        "registrationPage.paragraph.seconds": "sekund(-a)",
        "registrationPage.header.SMSConfirmation": "Potwierdzenie SMS",
        "registrationPage.paragraph.SMSConfirmationDescription":
          "Wpisz kod SMS, który wysłaliśmy na Twój numer telefonu, aby dokończyć rejestrację.",
        "registrationPage.paragraph.enterSMSCodeFrom": "Wpisz kod SMS z dnia",
        "registrationPage.input.placeholder.code": "Wpisz kod",
        "registrationPage.paragraph.sendCodeAgain":
          "Kod SMS został ponownie wysłany.",
        "registrationPage.button.sendCodeAgain": "Wyślij kod ponownie",
        "registrationPage.button.confirm": "Potwierdź",

        /* -- not found (404) page -- */
        "notFoundPage.header": "Błąd 404",
        "notFoundPage.subheader": "Żądany URL",
        "notFoundPage.subheaderExtend": "nie został znaleziony na serwerze.",
        "notFoundPage.subheaderExtend2":
          "Spróbuj sprawdzić, czy podany adres jest poprawny lub przejdź do",
        "notFoundPage.subheaderExtend3": "strony głównej",
        "notFoundPage.button.goToHomePage": "Przejdź do strony głównej",

        /* -- PaymentInfo Component -- */
        "global.toast.payment.count": "Nie wybrano wymaganych produktów",
        "paymentInfo.description.label": "Nazwa",
        "paymentInfo.amount.label": "Cena jedn.",
        "paymentInfo.quantity.label": "Liczba",
        "paymentInfo.total.label": "Razem",
        "paymentInfo.nocalculation": "Płatności nie są wymagane",
        "paymentInfo.summarycost.label": "Do zapłaty:",
        "paymentInfo.required.products": "Produkty obowiązkowe",
        "paymentInfo.optional.products": "Produkty opcjonalne",
        "paymentInfo.recalculate": "Przelicz",
        "paymentInfo.amount.to.paid": "Sumaryczna kwota do zapłaty ",
        "paymentInfo.info":
          "Sumaryczna liczba produktów obowiązkowych musi być równa liczbie osób w rezerwacji.",
        "paymentInfo.info1": "Musisz wybrać jeszcze",
        "paymentInfo.info3": "produkt",
        "paymentInfo.info4": "produkty",
        "paymentInfo.package.title": "Pakiet podstawowy",
        "paymentInfo.select.product": "Wybierz produkt",
        "paymentInfo.select.basic.product": "Wybierz produkt podstawowy",
        "paymentInfo.select.required.product": "Wybierz produkty obowiązkowe",
        "paymentInfo.too.much.products": "Nie możesz wybrać więcej produktów",
        "restaurantPage.button.confirmAndPay": "Potwierdź i zapłać",
        "restaurantPage.button.closestTime": "Najbliższy termin",
        "paymentInfo.title": "Opłaty",
        "reservation.payment.status.Created": "Utworzona",
        "reservation.payment.status.WaitingForPayment": "Oczekująca",
        "reservation.payment.status.ProcessingPayment":
          "W trakcie procesowania",
        "reservation.payment.status.Completed": "Zrealizowana",
        "reservation.payment.status.Cancelled": "Anulowana",
        "reservation.payment.status.Expired": "Wygasła",
        "reservation.payment.status.Unknown": "Nieznany status",

        /* -- custom form -- */
        "custom.form.required": "Pole wymagane",
        "custom.button.send": "Wyślij",
        "custom.button.hide": "Zwiń",
        "custom.button.show": "Rozwiń",

        /* -- reservation confirmation -- */
        "reservation.confirmation.payment.count": "Przelicz produkty",
        "reservation.confirmation.selected.promotion": "Wybrana promocja",

        /* -- forRestaurantsPage form -- */
        "forRestaurantsPage.form.toast.success":
          "Twoje dane kontaktowe zostały wysłane.",
        "forRestaurantsPage.form.toast.error":
          "Wystąpił błąd, spróbuj ponownie.",
        "forRestaurantsPage.form.email.error": "Niepoprawny email",
        "forRestaurantsPage.label.name": "IMIĘ I NAZWISKO",
        "forRestaurantsPage.label.restaurant": "RESTAURACJA:",
        "forRestaurantsPage.label.email": "E-MAIL:",
        "forRestaurantsPage.label.phone": "TELEFON:",
        "forRestaurantsPage.header.agreement": "OŚWIADCZENIA",
        "forRestaurantsPage.label.agreement":
          " Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl oraz na przesłanie oferty drogą elektroniczną.",

        /* -- Survey Page -- */
        "surveyPage.header.title": "Ankieta klienta",
        "surveyPage.header.description":
          "Wasza opinia jest dla nas najważniejsza, dzięki niej możemy się rozwijać. Prosimy podziel się z nami Swoją opinią:",
        "surveyPage.button.complete": "Gotowe",
        "surveyPage.button.back": "Wróć",
        "surveyPage.button.next": "Dalej",
        "surveyPage.surveyCompleteMessage":
          "Dziękujemy za wypełnienie ankiety!",
        "surveyPage.emptySurveyMessage":
          "Wystąpił błąd podczas pobierania danych ankiety. Prosimy spróbować później.",
        "surveyPage.error": "Wystąpił błąd.",
        "surveyPage.results.sending": "Trwa wysyłanie twoich odpowiedzi...",
        "surveyPage.results.success": "Twoja ankieta została wysłana",
        "surveyPage.filledAlert.header": "Ta ankieta została już wypełniona",
      }),
    },

    en: {
      translation: Object.assign({}, backendTranslationsEN, {
        /* -- seo --*/
        "seo.subpage.main.title": "Online table reservation – MojStolik.pl",
        "seo.subpage.main.description":
          "Book a table online in the most popular restaurants in Poland. Download the app, choose where you want to order a table and book. Its super easy with MojStolik.pl.",
        "seo.subpage.forRestaurant.title":
          "Restaurant booking platform - MojStolik.pl",
        "seo.subpage.forRestaurant.description":
          "MojStolik.pl is a platform for managing reservations in restaurants. All bookings in 1 place. An additional platform for communication with restaurant guests.",
        "seo.subpage.aboutUs.title":
          "An ultra-simple application for those who like to eat out.",
        "seo.subpage.aboutUs.description":
          "Hundreds of the most popular and respected restaurants in the country and thousands of users using the MojStolik.pl platform. Let the table wait for you and never the other way around.",
        "seo.subpage.howItWork.title":
          "Download the MojStolik.pl application on your phone and book tables",
        "seo.subpage.howItWork.description":
          "It only takes a few clicks to have a restaurant table waiting for you. You decide when, where and with whom you want to have lunch or dinner. Download the app and book tables.",
        "seo.subpage.documents.title":
          "Check what benefits you will get from cooperation with MojStolik.pl",
        "seo.subpage.documents.description":
          "Are you wondering if its worth it? Check the most important information in MojStolik.pl documents. Agreements, marketing offers and benefits from cooperation.",
        "seo.subpage.privacyPolicy.title":
          "MojStolik.pl as the administrator of personal data",
        "seo.subpage.privacyPolicy.description":
          "Read important information and get to know the MojStolik.pl platform",
        "seo.subpage.cookiesPolicy.title":
          "MojStolik.pl - discover restaurants in your area",
        "seo.subpage.cookiesPolicy.description":
          "MojStolik.pl uses cookies. Discover with us the most valued restaurants in your area.",
        "seo.subpage.registration.title":
          "Sign up and use the app today. MojStolik.pl",
        "seo.subpage.registration.description":
          "Table booking has never been so easy. Convenient payments, any time and number of people. Register and use MojStolik.pl today",
        "seo.subpage.login.title":
          "Log in to the application and choose restaurants from MojStolik.pl",
        "seo.subpage.login.description":
          "In our offer you will find the most popular restaurants in Poland. Download the app, log in and use the ultra-simple table booking system.",
        "seo.subpage.covid.restaurant.title":
          "COVID-19 - Responsible Restaurant",
        "seo.subpage.covid.restaurant.description":
          "Solutions for restaurants that will help keep business in these difficult times!",
        "seo.subpage.covid.client.title": "COVID-19 - Responsible guests",
        "seo.subpage.covid.client.description":
          "A guide for responsible guests of a responsible restaurant",
        /* -- covid --*/
        "covid.restaurant.1.strong": "PLAN B: ",
        "covid.restaurant.1.text":
          "Prepare YOUR contingency plan now in the event of an increase in the number of cases - think about the possibilities of maneuver and alternatives you have in the event of the reintroduction of restrictions of various degrees, how you will reorganize work, what procedures you will introduce, how you will maintain contact with guests, manage financial resources and plan the work of people. You are richer for spring experiences. Dont be surprised a second time.",
        "covid.restaurant.2.strong":
          "SAFETY AND HYGIENE is already a standard, but...: ",
        "covid.restaurant.2.text":
          "Dont drop the topic. Check that all recommended procedures related to the safety of guests and employees work flawlessly - face masks on the waiters, disinfection of tables after each guest, electronic menu as an option for guests, procedures for washing and disinfecting hands, keeping the right distance between tables and making payments as easy as possible non-cash. Dont be afraid to politely instruct guests on how to behave in your restaurant.",
        "covid.restaurant.3.strong": "BUILD YOUR COMMUNITY: ",
        "covid.restaurant.3.text":
          "Our observations show that those restaurants that managed to build a community around themselves and during the lockdown maintained contact with their guests via social media or text messages sent from the MojStolik.pl application fared much better in the new reality than those that did not communicate in an appropriate way. So take care of constant, interesting and reliable contact with your guests now – take care of social media, website, and also think about how else you will be able to communicate if necessary. Fortunately, the government, during its work in May, withdrew from the obligation for each restaurant to have a database of its guests, but as a responsible restaurateur, you should build such a database. This is where the use of the MojStolik.pl platform is very helpful - it makes it as easy as possible to collect and store data (name, email, telephone number) of guests in accordance with the GDPR. The data belongs to the restaurant.",
        "covid.restaurant.4.strong": "RESERVATION NECESSARY? ",
        "covid.restaurant.4.text":
          "In many countries of Europe and the world there were ideas that you could go to a restaurant only after booking a table. So be prepared for such an eventuality and take care of the technology.",
        "covid.restaurant.5.strong": "TRY TO MAKE YOUR GUESTS AWARE – ",
        "covid.restaurant.5.text":
          "use social media, but also other channels, to communicate why it is so important to follow the safety rules in the restaurant and what procedures you have introduced to minimize the risk of infection. Also make them aware that some of their behavior exposes you to losses that you cannot afford at this difficult time. Its mostly unused bookings that cost you time and money. Some people just dont realize how big of a problem this is for restaurateurs. Thanks to the Mojstolik.pl platform, the reservation cancellation process becomes very simple for guests and is fully automatic on the restaurant side.",
        "covid.restaurant.6.strong": "LOOK FOR SAVINGS: ",
        "covid.restaurant.6.text":
          "Especially today, every guest is a treasure and lets not let him wait for a busy waiter to pick up the reservation, who with a limited staff has much more responsibilities and may not be able to reach the phone in time. Our calculations show that the reservation system saves more than 20 hours of waiter or host work per month, because about 50% of reservations automatically go from the phone to the Internet and burden your team. Pure savings!",
        "covid.restaurant.7.strong": "OPTIMIZE THE USE OF THE ROOM: ",
        "covid.restaurant.7.text":
          "In the event of reduced restaurant occupancy, optimizing the management of reservations and table utilization is a key business issue. Check out ready-made technological solutions that will facilitate control over bookings coming from all communication channels: website, application, social media, telephone, walk-in. Such a solution is the MojStolik.pl platform.",
        "covid.restaurant.8.strong": "MINIMIZING THE NON-SHOW EFFECT: ",
        "covid.restaurant.8.text":
          "A reservation that is not canceled but the guest does not show up is the bane of restaurateurs. When the occupancy of the premises is only allowed at the level of 55-75%, such behavior of guests has real financial consequences. Therefore, try to minimize the risk of such events. MojStolik.pl sends guests SMS reminders about the reservation. Its possible cancellation is a matter of clicking on the link sent. By using this solution, you increase the possibility to fully use the potential of your restaurant and avoid losses. In London, gastronomes have revolted because this is not the time to miss out on their opportunity to make money.",
        "covid.restaurant.9.strong": "SAFE MENU: ",
        "covid.restaurant.9.text":
          "It is recommended that menus in restaurants be disposable and thrown away every time it ends up in someones hands. Such restrictions have been introduced as an obligation, e.g. in some US states. It is neither economical nor ecological. Maybe its worth considering the option of presenting your menu on the guests smartphone? Users of MojStolik.pl already have this possibility. The waiter gains the opportunity to suggest guests who have downloaded the application and thus familiarized themselves with the menu. You can also send an SMS with a link from the application before the visit and ask guests to introduce themselves to the atmosphere of the evening by selecting dishes earlier.☺ The application can also be downloaded at the table via a QR code. A fast, modern and imaginative option for some guests. Its worth proposing.",
        "covid.restaurant.10.strong": "CUSTOMIZE THE KITCHEN: ",
        "covid.restaurant.10.text":
          "The menu should be quite short and mostly based on seasonal, local or easily available products. Other variants are a risk, unless you are looking for an additional menu - easy to replace. In the event of problems and new restrictions, e.g. with long distance travel or closing borders, you will have no problems with deliveries and obtaining products for the chef. Reorganizing the work of the kitchen from day to day will be much easier. Costs are also significant.",
        "covid.restaurant.11.strong": "DOWNLOAD RESPONSIBLE SUPPLIERS: ",
        "covid.restaurant.11.text":
          "Search for business partners. Companies that will help, will understand and give new solutions. Look for suppliers of high-quality products in the vicinity of your business - relying on long-distance deliveries and hard-to-find products is too risky these days. Information on what companies do for their restaurants/partners can be found on the portal www.fortalks.pl.",
        "covid.restaurant.header": "A GUIDE TO A RESPONSIBLE RESTAURANT",
        "covid.restaurant.title": "A GUIDE TO A RESPONSIBLE RESTAURANT",
        "covid.restaurant.text1":
          "We all know that restaurants in Poland are struggling to survive, which is why they mostly have high standards of work, hygiene and communication with guests. Lets go a step further. The MojStolik.pl platform and the FOR Solutions team have developed a system and a package of ready-made solutions in the event of new restrictions and a possible second wave of the pandemic. The aim of the guide is to help restaurants survive in business and to reduce the risk of coronavirus infections as much as possible.",
        "covid.restaurant.text2":
          "The first wave of coronavirus and the related lockdown was a real shock for the catering market. It took everyone some time to shake it off and move on to operating in the new reality. Now we are familiar with the situation, richer in knowledge and experience on how to deal with crisis situations. However, in their own and well-understood interest, together with their guests, restaurants must do everything to prevent the spread of the virus. We have made a summary of how to prepare your restaurant to work in the face of the growing number of infections:",
        "covid.restaurant.welcome1": "Dear Restaurateur",
        "covid.restaurant.welcome2":
          "Here are 11 solutions that will help keep your business going during these difficult times!",
        "covid.restaurant.summary":
          "We hope that this set of tips will be helpful in adapting your restaurant to the new reality and in preparing for possible difficulties. If you have additional questions, we are here to help. If you want more information about cooperation with us, fill out the form. We will call you back or write back.",
        "covid.restaurant.greetings":
          "We want to help, thats why we join forces!",
        "covid.restaurant.team": "Team MojStolik.pl and FOR Solutions.",
        "covid.client.1.strong": "BASIC HYGIENE PRINCIPLES – ",
        "covid.client.1.text":
          "that is, when you enter the premises, put on a mask and take it off, only when you sit down at the table, disinfect your hands before entering (restaurants are required to have a liquid dispenser at the entrance). When you go to the toilet, cover your mouth and nose with a mask again. These issues are really crucial to limiting the risk of contracting COVID-19. ",
        "covid.client.2.strong": "SOCIAL DISTANCE – ",
        "covid.client.2.text":
          "we know that keeping the right distance is troublesome and we often forget ourselves, but simple rules are enough: do not make life difficult for the team and do not change the location of tables without the consent of the waiter or manager. They have been set up to ensure your safety.",
        "covid.client.3.strong": "TABLE CHOICE – ",
        "covid.client.3.text":
          "when you enter a restaurant (wearing a face mask!), wait for the waiter to show you the right table. In this way, you will make it easier for him to work and seat guests safely. If you can choose a seat yourself, sit only at tables that are marked as disinfected.",
        "covid.client.4.strong": "RESERVATION – ",
        "covid.client.4.text":
          "if you know you are going to a restaurant, e.g. tonight, book a table in advance. This makes it easier for restaurant employees to plan the seating of guests, but also to plan supplies and staff work. In addition, it shortens the time of your contact with the staff as much as possible, which is beneficial for both you and them. You can easily and quickly book a table in a selected restaurant using the MojStolik.pl application. Why wait for a table when it can wait for you?",
        "covid.client.5.strong": "CANCEL YOUR BOOKING IF YOU CANT COME! ",
        "covid.client.5.text":
          "Not showing up at the restaurant despite having made a reservation is not only, to put it mildly, rude, but also exposes the restaurant owner to real losses. He cant assign your table to anyone, because its reserved. He took you and your fellow guests into account when planning supplies. Such a situation is a real financial loss for him, now that the occupancy of the restaurant is reduced anyway, it is especially severe. The MojStolik.pl platform will also make it easier for you - it will not only remind you by text message when and where the chef is waiting for you, but it will also make it as easy as possible for you to cancel your reservation if necessary. Its just a click on one link!",
        "covid.client.6.strong": "TOUCHLESS MENU – ",
        "covid.client.6.text":
          "if you have a table reservation application on your smartphone, it will also allow you to familiarize yourself with the menu without having to pick up the restaurant. If you do not have the application, download it using special QR codes that are available at the tables.",
        "covid.client.7.strong": "PAY CONTACTLESS – ",
        "covid.client.7.text":
          "avoid direct contact and cash payments. Pay with a credit card, blik or phone.",
        "covid.client.8.strong": "DONT CRITIQUE – ",
        "covid.client.8.text":
          "it is still a very difficult time for an industry that fights for survival almost every day. Therefore, refrain from expressing critical remarks in public, and focus on positive emotions related to feasting. We all need good emotions right now, especially restaurants that are trying twice to survive.",
        "covid.client.9.strong": "SUPPORT – ",
        "covid.client.9.text":
          "if you were satisfied with your visit, be sure to tell about it in your social media, make INSTA STORIES and have fun praising the dishes and attracting other guests to your favorite restaurant!",
        "covid.client.page.title": "A GUIDE FOR RESPONSIBLE GUESTS",
        "covid.client.dear.guest": "Dear Guest",
        "covid.client.description":
          "No restaurant is right without you being. Its all for you. if not you support the gastro business, it will disappear he from the streets of our cities. And admit that a world without bars and restaurants would be terribly sad and empty, because meetings at table connect, entertain and develop our lives sociable.",
        "covid.client.description2":
          "Therefore, we appeal - support your favorite restaurants, visit them, but do it with your head. In the era of coronavirus, our common social responsibility is extremely important. Also, pay attention to some of your behaviors that cause real losses in restaurants. We give examples of them to help restaurants run more efficiently. And finally, remember all the safety rules to help restaurants maintain high standards of work, hygiene and organization. They care about it! Take care and YOU!",
        "covid.client.description3":
          "So how to responsibly enjoy time in your favorite restaurants? MojStolik.pl and FOR Solutions have prepared some tips for you:",
        "covid.client.summary":
          "By following these simple rules, you will take care of yourself your safety and that of your favorite crew restaurant, and being there and enjoying them delicious dishes, you really support her and you help me get through this difficult time. Image of ours cities without restaurants would be tragic…",
        /* -- global -- */
        "global.messages.sessionTimeout": "Session has expired",
        "global.form.message.requiredField": "Required field",
        "global.form.message.vatIDNumber":
          "VAT ID number should contain 10 digits",
        "global.form.message.invalidEmailFormat":
          "Invalid e-mail address format",
        "global.form.message.invalidPhoneFormat": "Invalid phone number format",
        "global.form.message.invalidBirthDateFormat":
          "Invalid date of birth format",
        "global.form.message.invalidPostCodeFormat":
          "Invalid postal code format. Expected XX-XXX",
        "global.form.message.invalidNipFormat": "Invalid NIP number format",
        "global.form.message.invalidKrsFormat": "Invalid KRS number format",
        "global.form.message.invalidRegonFormat": "Invalid REGON number format",
        "global.form.message.invalidUrlFormat": "Invalid URL format",
        "global.form.message.newPasswordsDoNotMatch":
          "New passwords do not match!",
        "global.form.message.atLeastNineCharacters":
          "At least nine characters required",
        "global.time.minutes": "minutes",
        /* -- metadata -- */
        "aboutPage.metadata.title": "About us",
        "aboutPage.metadata.description": "About us description",
        "aboutPage.metadata.keywords": "About us keywords",
        "accountPage.metadata.title": "Your account",
        "accountPage.metadata.description": "Your account description",
        "accountPage.metadata.keywords": "Your account keywords",
        "contactPage.metadata.title": "Contact",
        "contactPage.metadata.description": "Contact description",
        "contactPage.metadata.keywords": "Contact keywords",
        "documentsPage.metadata.title": "Documents",
        "documentsPage.metadata.description": "Documents description",
        "documentsPage.metadata.keywords": "Documents keywords",
        "forGuestPage.metadata.title": "For guest",
        "forGuestPage.metadata.description": "For guest description",
        "forGuestPage.metadata.keywords": "For guest keywords",
        "howItWorksPage.metadata.title": "How it works?",
        "howItWorksPage.metadata.description": "How it works description",
        "howItWorksPage.metadata.keywords": "How it works keywords",
        "loginPage.metadata.title": "Log in",
        "loginPage.metadata.description": "Log in description",
        "loginPage.metadata.keywords": "Log in keywords",
        "resetPasswordPage.metadata.title": "Password reset",
        "resetPasswordPage.metadata.description": "Password reset description",
        "resetPasswordPage.metadata.keywords": "Password reset keywords",
        "logoutPage.metadata.title": "Log out",
        "logoutPage.metadata.description": "Log out description",
        "logoutPage.metadata.keywords": "Log out keywords",
        "notFoundPage.metadata.title": "404 - Not found page!",
        "notFoundPage.metadata.description": "404 not found description",
        "notFoundPage.metadata.keywords": "",
        "priceListPage.metadata.title": "Price list",
        "priceListPage.metadata.description": "Price list description",
        "priceListPage.metadata.keywords": "Price list keywords",
        "registrationPage.metadata.title": "Registration",
        "registrationPage.metadata.description": "Registration description",
        "registrationPage.metadata.keywords": "Registration keywords",
        "reservationHistoryPage.metadata.title": "Your reservations",
        "reservationHistoryPage.metadata.description":
          "Your reservations description",
        "reservationHistoryPage.metadata.keywords":
          "Your reservations keywords",
        "searchMapPage.metadata.title": "Search results map",
        "searchMapPage.metadata.description": "Search results map description",
        "searchMapPage.metadata.keywords": "Search results map keywords",
        "searchPage.metadata.title": "Search results",
        "searchPage.metadata.description": "Search results description",
        "searchPage.metadata.keywords": "Search results keywords",
        "privacyPolicyPage.metadata.title": "Privacy policy",
        "privacyPolicyPage.metadata.description": "",
        "privacyPolicyPage.metadata.keywords": "",
        "cookiesPolicyPage.metadata.title": "Cookies policy",
        "cookiesPolicyPage.metadata.description": "",
        "cookiesPolicyPage.metadata.keywords": "",
        /* -- shortcuts -- */
        "shortcut.isClosed": "closed",
        "shortcut.monday": "Mo.",
        "shortcut.tuesday": "Tu.",
        "shortcut.wednesday": "We.",
        "shortcut.thursday": "Th.",
        "shortcut.friday": "Fr.",
        "shortcut.saturday": "Sa.",
        "shortcut.sunday": "Su.",
        /* -- default values -- */
        "defaultValues.restaurant": "Restaurant",
        /* -- restaurantPromotionInfoModal component -- */
        "restaurantPromotionInfoModal.button.use": "Use",
        "restaurantPromotionInfoModal.paragraph.promotionDetails":
          "Offer details",
        /* -- previewImageModal component -- */
        "previewImageModal.component.anchor.close": "Close",
        /* -- previewRestaurantModal component -- */
        "previewRestaurantModal.component.anchor.close": "Close",
        /* -- deleteAccountModal component -- */
        "deleteAccountModal.component.header": "Delete account",
        "deleteAccountModal.component.paragraph.deleteAccountDescription":
          "Are you sure you want to delete your account? Your booking history will also be deleted.",
        "deleteAccountModal.component.button.confirm": "Confirm",
        "deleteAccountModal.component.button.cancel": "Cancel",
        /* -- cancellationReservationModal component -- */
        "cancellationReservationModal.component.header":
          "Cancellation of reservation",
        "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
          "Are you sure you want to cancel your table reservation at {{restaurantName}} at {{reservationHour}} on {{reservationDate}}?",
        "cancellationReservationModal.component.button.confirm": "Confirm",
        "cancellationReservationModal.component.button.cancel": "Cancel",
        /* -- searchResultItem component -- */
        "searchResultItem.component.label.availableHours": "Available hours",
        "searchResultItem.component.label.futureReservationDaysError":
          "Remote bookings are available for {{days}} days ahead",
        "searchResultItem.component.label.noAvailableTables":
          "For selected search parameters (date, time, number of person) this restaurant does not offer tables for booking.",
        "searchResultItem.component.button.book": "Book now",
        "searchResultItem.component.button.cancel": "Cancel",
        "searchResultItem.component.button.see": "See more",
        /* -- passwordFormModal component -- */
        "passwordFormModal.component.header": "Enter the account password",
        "passwordFormModal.component.paragraph.deleteAccountDescription":
          "Enter your password to confirm the action.",
        "passwordFormModal.component.button.confirm": "Confirm",
        "passwordFormModal.component.button.cancel": "Cancel",
        /* -- cuisine types -- */
        "cuisine.romania": "Romanian cuisine",
        "cuisine.american": "American cuisine",
        "cuisine.arabic": "Arabic cuisine",
        "cuisine.chinese": "Chinese cuisine",
        "cuisine.polish": "Polish cuisine",
        "cusine.chinese": "Chinese cuisine",
        "cusine.polish": "Polish cuisine",
        /* -- cookies policy line -- */
        "component.cookiesPolicyLine.text":
          "This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use.",
        "component.cookiesPolicyLine.privacyPolicyText": "Privacy policy",
        /* -- header -- */
        "component.header.menu.mainPage": "Book a table",
        "component.header.menu.forRestaurants": "For restaurants",
        "component.header.menu.portal.restaurant": "Restaurant portal",
        "component.header.menu.forRestaurants.offer": "Our offer",
        "component.header.menu.blog": "News from MOJSTOLIK.PL",
        "component.header.menu.christmas": "Christmas",
        "component.header.menu.forGuest": "For visitor",
        "component.header.menu.howItWorks": "How it works?",
        "component.header.menu.priceList": "Price list",
        "component.header.menu.contact": "Contact",
        "component.header.menu.documents": "Documents",
        "component.header.menu.about": "About",
        "component.header.menu.login": "Log in",
        "component.header.menu.logout": "Log out",
        "component.header.menu.reservationHistory": "Your reservations",
        "component.header.menu.yourAccount": "Your account",
        "component.header.menu.covid": "COVID-19 guides",
        "component.header.menu.covid.restaurant": "Guide for restaurants",
        "component.header.menu.covid.client": "Guest guide",
        "component.header.menuMobile.covid.restaurant":
          "COVID-19 guide for restaurants",
        "component.header.menuMobile.covid.client": "COVID-19 guide for guests",
        /* -- footer -- */
        "component.footer.menu.header.forClients": "ABOUT MOJSTOLIK.PL",
        "component.footer.menu.header.informations": "Informations",
        "component.footer.menu.header.yourAccount": "Your account",
        "component.footer.menu.header.socialMedia": "Social media",
        "component.footer.menu.header.applicationDownload": "Download",
        "component.header.menu.events": "Events",
        "component.footer.menu.forRestaurants": "For restaurants",
        "component.footer.menu.forGuest": "For visitor",
        "component.footer.menu.howItWorks": "How it works?",
        "component.footer.menu.priceList": "Price list",
        "component.footer.menu.contact": "Contact",
        "component.footer.menu.documents": "Documents",
        "component.footer.menu.about": "About",
        "component.footer.menu.login": "Log in",
        "component.footer.menu.reservationHistory": "Your reservations",
        "component.footer.menu.yourAccount": "Your account",
        "component.footer.menu.logout": "Log out",
        "component.footer.menu.registration": "Registration",
        "component.footer.menu.privacyPolicy": "Privacy policy",
        "component.footer.menu.cookiesPolicy": "Cookies policy",
        /* -- index page -- */
        "indexPage.background.header": "Find the best restaurants in your city",
        "indexPage.header.currentPromotions": "Special offers and events",
        "indexPage.header.currentPromotionsExtend": "in your location",
        "indexPage.header.mostPopular": "Most popular",
        "indexPage.header.mostPopularExtend": "in your city",
        "indexPage.background.headerExtend": "and then book a table:",
        "indexPage.input.label.location": "Location",
        "indexPage.input.label.date": "Date",
        "indexPage.input.label.hour": "Hour",
        "indexPage.input.label.persons": "Number of person",
        "indexPage.input.label.persons.optionExtend": "p.",
        "indexPage.input.label.restaurantType": "Cuisine type",
        "indexPage.input.label.rating": "Rating",
        "indexPage.input.label.rating.optionExtend": "star(-s)",
        "indexPage.input.label.priceLevel": "Price level",
        "indexPage.input.label.searchByName": "Search by name",
        "indexPage.input.placeholder.select": "Select",
        "indexPage.input.placeholder.type": "Type in",
        "indexPage.input.placeholer.typeInOrSelect": "Type in or select",
        "indexPage.input.noData": "No results.",
        "indexPage.button.search": "Search",
        "indexPage.button.showAdditionalFilters": "Additional filters",
        "indexPage.button.hideAdditionalFilters": "Hide additional filters",
        "indexPage.button.use": "Use",
        "indexPage.button.promotion": "discount",
        "indexPage.button.bookNow": "Book now",
        /* -- account page -- */
        "accountPage.header": "Your account",
        "accountPage.subheader":
          "Change your personal data so that the process of your implementation is faster.",
        "accountPage.headerInColumn.yourPersonalData": "Your personal data",
        "accountPage.headerInColumn.yourPassword": "Your password",
        "accountPage.input.label.firstName": "First name",
        "accountPage.input.placeholder.firstName": "E.g. George",
        "accountPage.input.label.lastName": "Last name",
        "accountPage.input.placeholder.lastName": "E.g. Jones",
        "accountPage.input.label.phoneNumber": "Phone number",
        "accountPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
        "accountPage.input.label.email": "E-mail address",
        "accountPage.input.placeholder.email": "E.g. address@email.com",
        "accountPage.paragraph.optionalFields": "Optional fields",
        "accountPage.input.label.birthDate": "Date of birth",
        "accountPage.input.placeholder.birthDate": "E.g. 1976-10-25",
        "accountPage.input.label.postalCode": "Post code",
        "accountPage.input.placeholder.postalCode": "E.g. 03-123",
        "accountPage.input.label.city": "City",
        "accountPage.input.placeholder.city": "E.g. Warsaw",
        "accountPage.input.label.streetAndLocalNumber":
          "Street and local number",
        "accountPage.input.placeholder.streetAndLocalNumber":
          "E.g. Grójecka 130/105",
        "accountPage.button.save": "Save",
        "accountPage.button.deleteAccount": "Delete account",
        "accountPage.input.label.oldPassword": "Old password",
        "accountPage.input.label.newPassword": "New password",
        "accountPage.input.label.newPasswordRepeat": "New password (repeat)",
        "accountPage.button.changePassword": "Change password",
        "accountPage.message.personalDataUpdated":
          "Personal details have been updated.",
        "accountPage.message.accountPermanentlyDeleted":
          "Your account has been permanently deleted.",
        "accountPage.message.passwordChanged": "Password has been changed.",
        "accountPage.label.changePasswordNotPossible":
          "Changing the password is not possible because you logged in via Facebook or Google.",
        /* -- reservation history page -- */
        "reservationHistoryPage.header": "Your reservations",
        "reservationHistoryPage.subheader":
          "Here you can view your historical reservations and manage future reservations.",
        "reservationHistoryPage.paragraph.personsShortcut": "p.",
        "reservationHistoryPage.button.revoke": "Revoke",
        "reservationHistoryPage.button.pay": "Pay",
        "reservationHistoryPage.paragraph.additionalDescription":
          "Communication with client",
        "reservationHistoryPage.paragraph.date": "Date",
        "reservationHistoryPage.paragraph.duration": "Duration",
        "reservationHistoryPage.paragraph.hour": "Hour",
        "reservationHistoryPage.paragraph.persons": "Number of person",
        "reservationHistoryPage.paragraph.reservationIsEmpty":
          "The reservation does not exist",
        "reservationHistoryPage.paragraph.reservationListIsEmpty":
          "The reservation list is empty.",
        "reservationHistoryPage.paragraph.status": "Status",
        "reservationPage.paragraph.lateInfo":
          "Reminder, in case of being late the table awaits the Guest for 15 minutes only!",
        "reservationHistoryPage.paragraph.payment.status": "Payment status",
        /* -- search page -- */
        "searchPage.header": "Search results",
        "searchPage.button.map": "Map",
        "searchPage.button.list": "List",
        "searchPage.paragraph.noData": "No results for the given criteria.",
        /* -- search map page -- */
        "searchMapPage.map.button.refresh": "Refresh map",
        "searchMapPage.header": "Search results",
        "searchMapPage.button.map": "Map",
        "searchMapPage.button.list": "List",
        /* -- login page -- */
        "loginPage.header": "Log in",
        "loginPage.subheader":
          "As a logged in user you can reserve tables faster and view your booking history.",
        "loginPage.columns.header.standardLogin": "Standard log in",
        "loginPage.columns.header.socialLogin": "Log in with",
        "loginPage.input.label.phoneOrEmail": "Phone number",
        "loginPage.input.placeholder.phoneOrEmail": "",
        "loginPage.input.label.password": "Password",
        "loginPage.input.placeholder.password": "",
        "loginPage.anchor.passwordReset": "Password reset",
        "loginPage.button.logIn": "Log in",
        "loginPage.paragraph.dontHaveAccountYet":
          "You dont have an account yet?",
        "modal.login.dontHaveAccountYet": "You dont have an account yet",
        "loginPage.paragraph.registerNow": "Register now!",
        "loginPage.button.register": "Register now",
        "loginPage.message.logged": "Logged.",
        "loginPage.message.loggedViaFacebook": "Logged in with Facebook.",
        "loginPage.message.loggedViaApple": "Logged in with Apple.",
        "loginPage.message.loggedViaGoogle": "Logged in with Google.",
        "loginPage.paragraph.alreadyLoggedIn": "You are already logged in.",
        /* -- Reset password page -- */
        "resetPasswordPage.header": "Password reset",
        "resetPasswordPage.subheader":
          "Fill out the form below and we will send you a new account password via SMS",
        "resetPasswordPage.subheader2":
          "We will send you a new password to your phone number",
        "resetPasswordPage.input.label.phoneOrEmail": "Phone number",
        "resetPasswordPage.input.placeholder.phoneOrEmail": "",
        "resetPasswordPage.button.confirm": "Confirm",
        "resetPasswordPage.toast.passwordChanged":
          "The new password was sent via SMS to the phone number provided during registration.",
        "resetPasswordPage.toast.error.passwordChanged":
          "The phone number provided is incorrect.",
        /* -- for restaurants page -- */
        "forRestaurantsPage.header": "For restaurants",
        "forRestaurantsPage.subheader":
          "The offer is directed to the restaurant.",

        /* --forRestaurants page 2.0 - global-- */
        "forRestaurantsPage.global.perMonth": "PLN/month",
        "forRestaurantsPage.global.perMonth.short": "PLN/mth.",
        "forRestaurantsPage.global.button.choose": "CHOOSE",
        "forRestaurantsPage.global.button.next": "NEXT",
        "forRestaurantsPage.global.button.back": "BACK",
        "forRestaurantsPage.global.button.accept": "ACCEPT",
        "forRestaurantsPage.global.button.discover": "DISCOVER AVAILABLE PLANS",
        "forRestaurantsPage.global.button.send": "SEND",
        "forRestaurantsPage.global.button.learnMore": "Learn more",
        /* --forRestaurants page 2.0-- */
        "forRestaurantsPage.apiError":
          "We are experiencing some technical issues. Please try again later.",
        "forRestaurantsPage.1.header":
          "MOJSTOLIK.PL IS MUCH MORE THAN A SYSTEM",
        "forRestaurantsPage.1.subsection.1.header": "PORTAL & APP FOR GUESTS",
        "forRestaurantsPage.1.subsection.1.text":
          "A simple and convenient platform and app that enables fast table reservations in your restaurant.",
        "forRestaurantsPage.1.subsection.2.header": "TELEPHONE SYSTEM (IVR)",
        "forRestaurantsPage.1.subsection.2.text":
          "The IVR system will take reservations instead of your team, who can dedicate themselves to serving your guests in the restaurant.",
        "forRestaurantsPage.1.subsection.3.header":
          "PORTAL & APP FOR RESTAURANTS",
        "forRestaurantsPage.1.subsection.3.text":
          "Manage Your tables and reservations automatically or manually from our tablet app or from Your restaurant's website.",
        "forRestaurantsPage.2.header": "MANAGE FROM THE APPLICATION OR BROWSER",
        "forRestaurantsPage.2.text":
          "Use advanced app features on tablet and browser interface. Forget about the reservation book and switch to a universal solution for any type of restaurant.",
        "forRestaurantsPage.3.header": "FORGET ABOUT NO-SHOW",
        "forRestaurantsPage.3.text":
          "With the MOJSTOLIK.PL system, you minimize the risk of empty tables where guests should be sitting thanks to:",
        "forRestaurantsPage.3.listItem.1":
          "SMS confirmation and reminders of the reservation",
        "forRestaurantsPage.3.listItem.2":
          "The ability for quick and easy cancellation of reservations",
        "forRestaurantsPage.3.listItem.3":
          "Analysis of guests' historical activity.",
        "forRestaurantsPage.4.header":
          "EFFECTIVELY DIRECTING TRAFFIC TO THE RESTAURANT",
        "forRestaurantsPage.5.header":
          "WHAT ARE RESTAURANT OWNERS SAYING ABOUT US?",
        "forRestaurantsPage.5.opinion.1.title":
          "Mariusz Diakowski, owner of the Stary Dom restaurant:",
        "forRestaurantsPage.5.opinion.1.text":
          'Working with MojStolik.pl was a true revolution for Stary Dom. Neither I nor the restaurant team can imagine going back to the "book and pencil" era. MojStolik.pl actually conducted tests of its platform at our restaurant. We were a bit apprehensive, but we decided to take the risk. We do not regret it at all! All of our comments and suggestions were immediately implemented. Thanks to testing "on a live organism," this system really meets the needs of restaurants with high traffic, it makes work incredibly easier and shorter. No more pens, pencils, correcting fluid sticking pages, illegible handwriting, and impractical books. Now everything is organized and easy to use. I also want to add that the platform is practically flawless, and if there is a need, the helpdesk operates without a hitch.',
        "forRestaurantsPage.5.opinion.2.title":
          "Marek Katrycz, COO at STIXX Bar&Grill",
        "forRestaurantsPage.5.opinion.2.text":
          "MojStolik.pl is a very modern and innovative system that allows us, as a large restaurant, to effectively manage guest reservations and seating arrangements, which simplifies the work of our team. We highly recommend this system, they are constantly improving its functionality, it is intuitive and very easy to use. It has reduced the time it takes to accept reservations and find them, which is particularly helpful during high occupancy",
        "forRestaurantsPage.5.opinion.3.title":
          "Robert Goluba, Brand Manager at Bombaj Masala:",
        "forRestaurantsPage.5.opinion.3.text":
          "Why did we decide on the MojStolik.pl system in our restaurants? Good reputation in the industry and a really pleasant and professional approach to new customers. The system is very intuitive, but the training for the staff took as long as it took for each restaurant employee to be able to handle the panel, so there were no problems with implementing MojStolik in the restaurant. It went without any problems, which is probably unprecedented for new software. The first day with a large number of guests and reservations in our restaurant was enough to convince us of the advantages of solutions in the MojStolik.pl system. I am sure that it allowed us (and still does) to smoothly receive guests and, as a result, to serve more guests. This naturally translates into financial results. If after a few weeks someone ordered me to return to work with a traditional reservation book, I would feel like... in the 20th century",
        "forRestaurantsPage.6.header": "DISCOVER THE PLANS OF MOJSTOLIK.PL",
        "forRestaurantsPage.6.subheader":
          "Choose a plan for your restaurant and start working with us.",
        "forRestaurantsPage.discountCodeInput": "Enter discount code",
        "forRestaurantsPage.discountCodeButton": "Redeem",
        "forRestaurantsPage.discountCodeLoading": "Loading...",
        "forRestaurantsPage.discountCodeSuccess": "Discount code applied",
        "error.promotion.not.exists": "Discount code does not exist",
        "forRestaurantsPage.allOffers":
          "To learn about a detailed comparison of MOJSTOLIK.PL plans",
        "forRestaurantsPage.allOffers.orange": "click here",
        "forRestaurantsPage.form.title":
          "If you are unsure and would like to learn more about the MojStolik.pl platform, please fill out this short form and we will contact you:",
        /* --forRestaurants page 2.0 - komponent ofert-- */
        "forRestaurantsPage.offers.offerItem.1.header": "LIGHT",
        "forRestaurantsPage.offers.offerItem.1.text":
          "For restaurants that value the simplicity of automating the reservation collection process in real-time.",
        "forRestaurantsPage.offers.offerItem.2.header": "ADVANCED",
        "forRestaurantsPage.offers.offerItem.2.text":
          "For restaurants that not only want to manage reservations, but also want to manage rooms and tables.",
        "forRestaurantsPage.offers.offerItem.3.header": "ADVANCED",
        "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
        "forRestaurantsPage.offers.offerItem.3.text":
          "For large restaurants where full automation of all communication channels with guests is essential.",
        "forRestaurantsPage.offers.subscription": "Subscription",
        "forRestaurantsPage.offers.commission": "Commission",
        "forRestaurantsPage.offers.commission.currency": "PLN",
        "forRestaurantsPage.offers.header": "Additional options",
        "forRestaurantsPage.offers.additional-options.prepayments":
          "Prepayment module for reservations",
        "forRestaurantsPage.offers.additional-options.ivr":
          "Automatic handling of phone reservations (IVR) with an additional 300 SMS/month package",
        "forRestaurantsPage.offers.additional-options.smsPackage":
          "Additional SMS message package - 1000 pieces/month",
        "forRestaurantsPage.offers.mostPopular": "Most popular choice",
        "forRestaurantsPage.offers.pricingSetting1":
          "The above prices apply to the annual billing period. Switch to monthly billing: ",
        "forRestaurantsPage.offers.pricingSetting2":
          "The above prices apply to the monthly billing period. Switch to annual billing: ",
        "forRestaurantsPage.offers.bulletPoint.1":
          "1. All prices in the offer are net prices in PLN and the legally required VAT tax will be added at the rate applicable on the invoice date.",
        "forRestaurantsPage.offers.bulletPoint.2":
          "2. In case of the annual payment model, the amount of the monthly fee is presented, however, the payment is made in a one-time lump sum for 12 months in advance.",
        "forRestaurantsPage.offers.bulletPoint.3":
          "3. A marketing discount of 50 PLN net is granted based on the monthly fee, provided that the restaurant shares the reservation link via MOJSTOLIK.PL on the restaurant's social media at least twice a month.",
        "forRestaurantsPage.offers.bulletPoint.4":
          "4. The cost of an SMS outside of the subscription is 0.09 PLN net.",
        "forRestaurantsPage.offers.bulletPoint.5":
          "5. In case of using the Prepaid Module, a fee of 1.59% of the transaction amount is charged by the payment operator for each transaction.",
        /* --forRestaurants page 2.0 - porównanie ofert-- */
        "forRestaurantsPageAllOffers.header":
          "Discover the plans of MOJSTOLIK.PL",
        "forRestaurantsPageAllOffers.subheader":
          "Choose a plan for your restaurant and start working with us.",
        "forRestaurantsPageAllOffers.offer.1": "LIGHT",
        "forRestaurantsPageAllOffers.offer.2": "ADVANCED",
        "forRestaurantsPageAllOffers.offer.3": "ADVANCED",
        "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
        "forRestaurantsPageAllOffers.listHeader.1":
          "Reservation channels available for customers",
        "forRestaurantsPageAllOffers.listHeader.2":
          "Restaurant reservation management",
        "forRestaurantsPageAllOffers.listHeader.3":
          "Restaurant profile configuration",
        "forRestaurantsPageAllOffers.listHeader.4":
          " Accessibility for restaurants from devices",
        "forRestaurantsPageAllOffers.listHeader.5": "Additional services",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.1":
          "Reservations from the mojstolik.pl website",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
          "Reservations from the dedicated MojStolik mobile application",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.3":
          "Reservations from the restaurant's website",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.4":
          "Reservations from Google Maps",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.5":
          "Reservations from Facebook",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
          "Confirmations  /reminders of reservations through SMS/app notifications**",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
          "Automatic handling of telephone reservations",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.8":
          "Reservations from the restaurant's Instagram",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
          "Reservations from email inbox (autoresponder)",
        "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
          "Links / QR codes in restaurant PR articles and materials",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.1":
          "Reservation list - tabular view",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.2":
          "Reservations on room map",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.3":
          "Gantt view (reservation schedule)",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.4":
          "Reservation details and editing",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.5":
          "Advanced reservation management",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.6":
          "Daily reservation report",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.7":
          "Pre-payment handling for reservations",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.8":
          "Walk-in handling",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
          "Reservation history/analysis of guest preferences and behavior",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.10":
          "Minimization of no-show effect",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.11":
          "Working in live mode",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
          "Restaurant customer database/compliance with GDPR",
        "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
          "DATA SECURITY / Encrypted system and different access levels based on roles",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
          "Restaurant's business card and individual profile on customer portal and mobile app",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.2":
          "Restaurant descriptive data",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.3":
          "Graphic materials",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.4":
          "Availability hours management",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.5":
          "Breaks and special days",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.6":
          "Reservation acceptance parameterization",
        "forRestaurantsPageAllOffers.listHeader.3.listItem.7":
          "Room and table configuration",
        "forRestaurantsPageAllOffers.listHeader.4.listItem.1":
          "Management portal for restaurant",
        "forRestaurantsPageAllOffers.listHeader.4.listItem.2":
          "Restaurant tablet app",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.1":
          "Help desk and technical support",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
          "Onboarding training for restaurant staff",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
          "Presence/promotion of restaurant on MojStolik.pl social media channels",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
          "Number of SMS messages available in subscription",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light":
          "100 SMS/mth",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced":
          "300 SMS/mth",
        "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro":
          "600 SMS/mth",
        /* --forRestaurants page 2.0 - formularz-- */
        "forRestaurantsPageForm.downloadButton": "DOWNLOAD DATA",
        "forRestaurantsPageForm.header":
          "This is where you start your collaboration with MOJSTOLIK.PL",
        "forRestaurantPageForm.apiError":
          "Failed to download offer data. Sorry for the inconvenience, please try again later.",
          "forRestaurantPageForm.paramsError": "Sorry, we couldn't get the offer details. To see our offers please visit",
        "forRestaurantPageForm.paramsError.link": "this site",
        "forRestaurantsPageForm.chosenPlan": "Selected MOJSTOLIK.PL plan",
        "forRestaurantsPageForm.discountCode": "Discount code: ",
        "forRestaurantsPageForm.payment.yearly":
          "The selected plan is billed with a yearly period.",
        "forRestaurantsPageForm.payment.monthly":
          "The selected plan is billed with a monthly period.",
        "forRestaurantsPageForm.additionalOptions": "Additional options:",
        "forRestaurantsPageForm.formHeader.1":
          "Provide basic restaurant information",
        "forRestaurantsPageForm.formHeader.2":
          "Provide contact person information",
        "forRestaurantsPageForm.formHeader.3": "Provide Contract data",
        "forRestaurantsPageForm.formHeader.4": "Provide representative data",
        "forRestaurantsPageForm.formHeader.5": "MOJSTOLIK.PL Contract",
        "forRestaurantsPageForm.restaurantName": "Restaurant name",
        "forRestaurantsPageForm.street": "Street",
        "forRestaurantsPageForm.apartment": "Local",
        "forRestaurantsPageForm.postalCode": "Postal code",
        "forRestaurantsPageForm.city": "City",
        "forRestaurantsPageForm.email": "Email address",
        "forRestaurantsPageForm.phoneNumber": "Phone number",
        "forRestaurantsPageForm.website": "Website address",
        "forRestaurantsPageForm.website.menu":
          "Restaurant menu website address",
        "forRestaurantsPageForm.name": "Name",
        "forRestaurantsPageForm.surname": "Surname",
        "forRestaurantsPageForm.title": "Title",
        "forRestaurantsPageForm.entity": "Entity name",
        "forRestaurantsPageForm.email.invoice": "Invoice email address",
        "forRestaurantsPageForm.email.contact": "Contact email address",
        "forRestaurantsPageForm.proxy": "Type of power of attorney",
        "forRestaurantsPageForm.select.owner": "Owner",
        "forRestaurantsPageForm.select.manager": "Manager",
        "forRestaurantsPageForm.select.boardMember": "Board Member",
        "forRestaurantsPageForm.select.other": "Other",
        "forRestaurantsPageForm.contract": "Contract content",
        "forRestaurantsPageForm.pdfLink": "Download pdf",
        "forRestaurantsPageForm.statement.1":
          "I agree to the processing of my personal data by Table4Rest Ltd. for the purpose of entering into a contract for the provision of services for access to and use of the MojStolik.pl application.",
        "forRestaurantsPageForm.statement.2":
          "I accept the above contract conditions and the MOJSTOLIK.PL regulations and I declare that I am authorized to enter into a contract for the provision of services for access to and use of the MojStolik.pl application on behalf of the restaurant.",
        "forRestaurantsPageForm.loading": "Your form is being submitted...",
        "forRestaurantsPageForm.success": "Form submission successful.",
        "forRestaurantsPageForm.nipError": "Enter correct VAT number",
        "forRestaurantsPageForm.loadingData": "Data download in progress...",
        "forRestaurantsPageForm.error": "Error occurred.",
        "forRestaurantsPageForm.dataSuccess": "Data retrieved successfully",
        "forRestaurantsPageForm.generatingContract":
          "Contract is being generated...",
        /* --forRestaurants page 2.0 - sukces-- */
        "forRestaurantsPageSuccess.header":
          "Thank you for choosing the offer from Mojstolik.pl",
        "forRestaurantsPageSuccess.text.1":
          "IN A MOMENT, A DOCUMENT OF THE AGREEMENT WHICH WAS ACCEPTED IN THE PREVIOUS STEP WILL BE SENT TO THE E-MAIL ADDRESS PROVIDED.",
        "forRestaurantsPageSuccess.text.2":
          "At the same time, we will prepare and provide you with the necessary instructions that will allow you to configure your restaurant in the system and start cooperation.",
        "forRestaurantsPageSuccess.text.3": "See you!",
        "forRestaurantsPageSuccess.button": "Return to the main page",

        /* -- cookies policy page -- */
        "cookiesPolicyPage.header": "Cookies policy",
        "cookiesPolicyPage.subheader": "Important information.",
        "cookiesPolicyPage.point.1":
          "The website does not automatically collect any information, except for information contained in cookie files.",
        "cookiesPolicyPage.point.2":
          'Cookie files (so-called "cookies") are IT data, in particular text files, which are stored in the Website Users end device and are intended for using the Websites websites. Cookies usually contain the name of the website they come from, their storage time on the end device and a unique number.',
        "cookiesPolicyPage.point.3":
          "The entity placing cookies on the Website Users end device and accessing them is the owner of mojstolik.pl, Table4Rest Sp. z o. o. with its registered office at ul. Ksawerów 21, 02-656 Warsaw.",
        "cookiesPolicyPage.point.4": "Cookies are used to:",
        "cookiesPolicyPage.point.4a":
          "adapting the content of the Website pages to the Users preferences and optimizing the use of websites; in particular, these files allow to recognize the Website Users device and properly display the website, tailored to his individual needs;",
        "cookiesPolicyPage.point.4b":
          "creating statistics that help to understand how Website Users use websites, which allows improving their structure and content;",
        "cookiesPolicyPage.point.4c":
          "maintaining the Website Users session (after logging in), thanks to which the User does not have to re-enter the login and password on each subpage of the Website;",
        "cookiesPolicyPage.point.5":
          'The Website uses two basic types of cookies: "session" (session cookies) and "permanent" (persistent cookies). Session cookies are temporary files that are stored on the Users end device until logging out, leaving the website or turning off the software (web browser). "Permanent" cookies are stored on the Users end device for the time specified in the cookie file parameters or until they are deleted by the User.',
        "cookiesPolicyPage.point.6":
          "The following types of cookies are used on the Website:",
        "cookiesPolicyPage.point.6a":
          '"necessary" cookies, enabling the use of services available on the Website, e.g. authentication cookies used for services requiring authentication on the Website;',
        "cookiesPolicyPage.point.6b":
          "cookies used to ensure security, e.g. used to detect fraud in the field of authentication within the Website;",
        "cookiesPolicyPage.point.6c":
          "performance cookies, enabling the collection of information on how the Website pages are used;",
        "cookiesPolicyPage.point.6d":
          '"functional" cookies, enabling "remembering" the settings selected by the User and personalizing the Users interface, e.g. in terms of the selected language or region from which the User comes from, font size, website appearance, etc.;',
        "cookiesPolicyPage.point.6e":
          '"advertising" cookies, enabling the provision of advertising content to Users more tailored to their interests.',
        "cookiesPolicyPage.point.7":
          "In many cases, the software used for browsing websites (web browser) allows cookies to be stored on the Users end device by default. Website users may change their cookie settings at any time. These settings can be changed in particular in such a way as to block the automatic handling of cookies in the web browser settings or to inform about each time they are placed on the Website Users device. Detailed information on the possibilities and ways of handling cookies are available in the software (web browser) settings.",
        "cookiesPolicyPage.point.8":
          "The Website Operator informs that restrictions on the use of cookies may affect some of the functionalities available on the Websites websites.",
        /* -- privacy policy page -- */
        "privacyPolicyPage.header": "Privacy policy",
        "privacyPolicyPage.subheader": "Important information.",
        "privacyPolicyPage.point.one": "GENERAL PROVISIONS",
        "privacyPolicyPage.point.one.1":
          "The data administrator is Table4Rest Sp. z o. o. with its registered office in Warsaw 02-656, ul. Ksawerów 21, entered into the Register of Entrepreneurs under the KRS number: 0000752110, for which the registration files are kept by the DISTRICT COURT FOR THE CITY OF WARSAW IN WARSAW, 13th COMMERCIAL DEPARTMENT OF THE NATIONAL COURT REGISTER NIP: 5213842139 REGON: 381529521",
        "privacyPolicyPage.point.one.2":
          "For the interpretation of terms, the glossary of the Regulations is used or as described in the Privacy Policy (if it results directly from the description).",
        "privacyPolicyPage.point.one.3":
          'For the purposes of better reception of the Privacy Policy, the term "User" has been replaced with the term "You", "Administrator" - "We". The term "GDPR" means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.',
        "privacyPolicyPage.point.one.4":
          "We respect the right to privacy and we care about data security. For this purpose, e.g. secure communication encryption protocol (SSL).",
        "privacyPolicyPage.point.one.5":
          "Personal data provided in the form on the landing page are treated as confidential and are not visible to unauthorized persons.",
        "privacyPolicyPage.point.two": "DATA ADMINISTRATOR",
        "privacyPolicyPage.point.two.1":
          "The service provider is the administrator of people who have used the contact form on the website or used other forms of communication (e-mail, telephone, mobile application, plug-in order a conversation, other forms of communication).",
        "privacyPolicyPage.point.two.2": "Personal data is processed:",
        "privacyPolicyPage.point.two.2a":
          "a. in accordance with the provisions on the protection of personal data,",
        "privacyPolicyPage.point.two.2b":
          "b. in accordance with the implemented Privacy Policy,",
        "privacyPolicyPage.point.two.2c":
          "c. to the extent and for the purpose necessary to establish, shape the content of the Agreement, change or terminate it and the proper implementation of the Services provided,",
        "privacyPolicyPage.point.two.2d":
          "d. to the extent and purpose necessary to fulfill legitimate interests (legitimate purposes), and the processing does not violate the rights and freedoms of the data subject.",
        "privacyPolicyPage.point.two.3":
          "Each data subject (if we are their administrator) has the right to access data, rectify, delete or limit processing, the right to object, the right to lodge a complaint with the supervisory authority.",
        "privacyPolicyPage.point.two.4":
          "Contact with the person supervising the processing of personal data in the Service Providers organization is possible by electronic means at the following e-mail address: konkt@mojstolik.pl",
        "privacyPolicyPage.point.two.5":
          "We reserve the right to process your data after termination of the Agreement or withdrawal of consent only to the extent necessary to pursue possible claims before the court or if national or EU regulations or international law oblige us to retain data.",
        "privacyPolicyPage.point.two.6":
          "The Service Provider has the right to share the Users personal data and other data with entities authorized under applicable law (e.g. law enforcement authorities).",
        "privacyPolicyPage.point.two.7":
          "Deletion of personal data may occur as a result of withdrawing consent or filing a legally permissible objection to the processing of personal data.",
        "privacyPolicyPage.point.two.8":
          "The Service Provider does not disclose personal data to entities other than those authorized under applicable law.",
        "privacyPolicyPage.point.two.9":
          "Personal data is processed only by persons authorized by us or by processors with whom we closely cooperate.",
        /* -- restaurant profile page -- */
        "restaurantPage.button.pageWWW": "Web WWW",
        "restaurantPage.button.seeMenu": "See menu",
        "restaurantPage.button.confirm": "Confirm",
        "restaurantPage.button.cancel": "Cancel",
        "restaurantPage.button.choose": "Choose",
        "restaurantPage.button.back": "Back",
        "restaurantPage.button.showMore.promotion": "More",
        "restaurantPage.button.showMore": "Show more",
        "restaurantPage.button.showLess": "Show less",
        "restaurantPage.button.goToHomePage": "Back to main page",
        "restaurantPage.button.reservationDetails": "Reservation details",
        "restaurantPage.button.goToPaymentPage": "Go to payment panel",
        "restaurantPage.paymentPage.info":
          "You will be redirected to the payment operator right away Przelewy24.pl",
        "restaurantPage.header.restaurantDescription": "Restaurant description",
        "restaurantPage.header.bookingPolicy": "Booking policy",
        "restaurantPage.header.gallery": "Gallery",
        "restaurantPage.header.yourReservation": "Your reservation",
        "restaurantPage.header.yourPersonalData": "Your personal data",
        "restaurantPage.header.ctnWithoutLogin": "Continue without logging in",
        "restaurantPage.header.login": "Log in",
        "restaurantPage.button.login": "Sign in",
        "restaurantPage.button.orLoginWith": "Or sign in using",
        "restaurantPage.header.createAccount":
          "Create an account on MojStolik.pl and gain:",
        "restaurantPage.header.createAccount.benefit.1": "Quick booking",
        "restaurantPage.header.createAccount.benefit.2": "Full booking history",
        "restaurantPage.header.createAccount.benefit.3":
          "Access to unique promotions",
        "restaurantPage.button.createAccount": "Create a MojStolik.pl account",
        "restaurantPage.header.statements": "Statements",
        "restaurantPage.header.promotions": "Special offers and events",
        "restaurantPage.header.use": "Use",
        "restaurantPage.header.cancel": "Cancel",
        "restaurantPage.header.reservationConfirmation":
          "Reservation Confirmation",
        "restaurantPage.header.reservationsPersons":
          "Reservations from {{min}} to {{max}} people",
        "restaurantPage.paragraph.reservationConfirmationDescription":
          "Enter the SMS code we sent to your phone number to complete the table booking process",
        "restaurantPage.input.label.code": "Enter the SMS code from",
        "restaurantPage.input.placeholder.code": "",
        "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
          "No hours available in these criteria (date, number of person, promotion). Choose a different date, number of person or promotion.",
        "restaurantPage.paragraph.noAvailableHours":
          "No hours available in these criteria.",
        "restaurantPage.paragraph.noAvailableHoursExt":
          "Choose a different date or number of person.",
        "restaurantPage.input.label.date": "Date",
        "restaurantPage.input.label.hour": "Hour",
        "restaurantPage.input.label.persons": "Number of person",
        "restaurantPage.input.label.persons.optionExtend": "p.",
        "restaurantPage.input.label.firstName": "First name",
        "restaurantPage.input.label.vatIdNumber": "Vat ID Number",
        "restaurantPage.input.label.address": "Address",
        "restaurantPage.input.label.companyName": "Company Name",
        "restaurantPage.input.label.street": "Street",
        "restaurantPage.input.label.buildingNumber": "Building number",
        "restaurantPage.input.label.localNumber": "Local number",
        "restaurantPage.input.label.postalCode": "Postal code",
        "restaurantPage.input.label.city": "City",
        "restaurantPage.input.label.country": "Country",
        "restaurantPage.get.company.data": "Download",
        "restaurantPage.input.label.lastName": "Last name",
        "restaurantPage.input.label.phoneNumber": "Phone number",
        "restaurantPage.input.label.email": "E-mail address",
        "restaurantPage.input.label.password": "Password",
        "restaurantPage.input.label.passwordRepeat": "Repeat password",
        "restaurantPage.input.label.description":
          "Comments regarding the reservation",
        "restaurantPage.input.label.footer":
          "MojStolik uses various electronic communication channels and conducts business using e-mail, telephone, SMS and MMS. The administrator of your personal data is Table4Rest limited liability company with its registered office in Warsaw, 02-656 Warsaw, Ksawerów 21, KRS 0000752110, NIP 5213842139. Your personal data will be processed in order to direct marketing content to you in electronic communication channels. More information on the processing of personal data, including your rights, can be found in the Privacy Policy.",
        "agreements.allAgreements": "Check all",
        "agreements.invoice": "Provide the invoice details",
        "restaurantPage.input.label.personalData":
          "I declare that I am aware of and accept the provisions of the Terms and Conditions for the provision of services by MojStolik via the Customer Application. ",
        "restaurantPage.input.label.personalData.extension":
          "Regulamin MojStolik",
        "restaurantPage.input.label.readWithdrawal":
          "[EN] Zapoznałem się z pouczeniem o prawie odstąpienia od",
        "restaurantPage.input.label.readWithdrawal.extension":
          "agreement with the customer mojstolik.pl",
        "restaurantPage.input.label.agreementMarketing":
          "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information about MojStolik services and products available on the Customer Application, via electronic communication. You may withdraw this consent at any time.",
        "restaurantPage.input.label.marketingAgreement3thPart":
          "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information to me about the services and products of the Restaurant and Cooperating Entities indicated on mojstolik.co.uk, available in the Customer Application, via electronic communication. You may withdraw this consent at any time.",
        "restaurantPage.input.label.marketingAgreement3thPart.extension":
          "at this link",
        "agreement.eventAgreement":
          "I have read and accept the terms and conditions of special offers",
        "restaurantPage.input.placeholder.typeIn": "Type in",
        "restaurantPage.input.placeholder.firstName": "E.g. George",
        "restaurantPage.input.placeholder.lastName": "E.g. Jones",
        "restaurantPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
        "restaurantPage.input.placeholder.email": "E.g. address@email.com",
        "restaurantPage.paragraph.fillOrLogin":
          "Fill out the form or download personal data from Facebook, Google or Apple",
        "restaurantPage.paragraph.doYouWantToRegister":
          "If you would like to create a MOJSTOLIK.PL account please set a password",
        "restaurantPage.paragraph.dataIsIncorrect": "The data is incorrect?",
        "restaurantPage.paragraph.sendCodeAgainText":
          "The code was sent again via SMS.",
        "restaurantPage.anchor.sendCodeAgain": "Resend code",
        "restaurantPage.anchor.edit": "Edit",
        "restaurantPage.anchor.login": "Login",
        "restaurantPage.paragraph.or": " or ",
        "restaurantPage.anchor.register": "register",
        "restaurantPage.paragraph.dontWontUsePromotion":
          "I don't want to use the promotion",
        "restaurantPage.messages.codeResent": "The SMS code has been resent.",
        "restaurantPage.header.reservationRecieved": "Reservation recieved",
        "restaurantPage.summary.paragraph.firstLastName": "Name Surname:",
        "restaurantPage.summary.paragraph.phoneNumber": "Phone number:",
        "restaurantPage.summary.paragraph.email": "E-mail address:",
        "restaurantPage.summary.paragraph.restName": "Restaurant name:",
        "restaurantPage.summary.paragraph.date": "Date:",
        "restaurantPage.summary.paragraph.date.time": ", at",
        "restaurantPage.summary.paragraph.duration": "Duration:",
        "restaurantPage.summary.paragraph.persons": "Guests:",
        "restaurantPage.summary.paragraph.totalCost": "Prepayments:",
        "restaurantPage.summary.special.offer": "Special offer:",
        "restaurantPage.summary.paragraph.state": "Status:",
        "restaurantPage.paragraph.tableReserved": "A table was reserved",
        "reservation.status.Canceled": "Rejected",
        "reservation.status.Completed": "Booking completed",
        "reservation.status.Confirmed": "The reservation has been confirmed",
        "reservation.status.Delayed": "Delayed",
        "reservation.status.NotCompleted": "Unrealized booking",
        "reservation.status.Rejected": "The reservation was canceled",
        "reservation.status.WaitingForApproval":
          "The reservation is awaiting for approval",
        "reservation.status.WaitingForConfirmation": "Awaiting",
        "restaurantPage.paragraph.tableReservedDescription":
          "Your table has been reserved at",
        "restaurantPage.paragraph.tableReservedDescriptionInDay": "on",
        "restaurantPage.paragraph.tableWaitingForApproval":
          "Your reservation is waiting for approval",
        "restaurantPage.paragraph.tableWaitingForApprovalDescription1":
          "Your reservation at",
        "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
          "If you will not receive confirmation in the next few hours please contact the Restaurant directly",
        "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay":
          "on",
        "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
          "After successful approval you will get sms notification with payment link. Your reservation will be valid after payment confirmation",
        "restaurantPage.paragraph.tableReservedPaymentInformation":
          "Reservation needs to be payed",
        /* -- for guests page -- */
        "forGuestPage.header": "For visitor",
        "forGuestPage.subheader": "",
        /* -- how it works page -- */
        "howItWorksPage.header": "How it works?",
        "howItWorksPage.subheader": "Step by step",
        "howItWorksPage.content.title":
          "Reservation from the mobile application",
        "howItWorksPage.content.step1": "Step 1",
        "howItWorksPage.content.step1.content":
          "The customer in the mobile application searches for restaurants and ",
        "howItWorksPage.content.step1.content.strong":
          "sees available tables in real time.",
        "howItWorksPage.content.step2": "Step 2",
        "howItWorksPage.content.step2.content.strong":
          "The manager sees the booking request in his panel",
        "howItWorksPage.content.step2.content":
          " He knows if the request came from a new customer or a regular one and if there are any table preferences.",
        "howItWorksPage.content.step3": "Krok 3",
        "howItWorksPage.content.step3.content":
          "The customer comes to the restaurant where ",
        "howItWorksPage.content.step3.content.strong":
          "is greeted by name. The service has full customer details",
        "howItWorksPage.content.step3.contentb":
          " — knows if he is their regular guest or if he is visiting them for the first time.",
        "howItWorksPage.phone.title": "Telephone booking",
        "howItWorksPage.content.phone.step1": "Step 1",
        "howItWorksPage.content.phone.step1.content1": "Client ",
        "howItWorksPage.content.phone.step1.content.strong":
          "calls the selected restaurant ",
        "howItWorksPage.content.phone.step1.content2":
          "with a booking request.",
        "howItWorksPage.content.phone.step2": "Step 2",
        "howItWorksPage.content.phone.step2.content.strong":
          "The manager in his panel sees the availability of tables in real time",
        "howItWorksPage.content.phone.step2.content":
          ". By entering the customers phone number, the manager immediately sees his data or completes / enters the data of a new customer.",
        "howItWorksPage.content.phone.step3": "Step 3",
        "howItWorksPage.content.phone.step3.content":
          "The customer comes to the restaurant where ",
        "howItWorksPage.content.phone.step3.content.strong":
          "the staff greets him by name, he has full customer details",
        "howItWorksPage.content.phone.step3.contentb":
          " — knows if he is their regular guest or if he is visiting them for the first time.",
        /* -- price list page -- */
        "priceListPage.header": "Price list",
        "priceListPage.subheader": "",
        "priceListPage.content.link.1":
          "Please contact us for pricing information ",
        "priceListPage.content.link.2": "contact",
        /* -- contact page -- */
        "contactPage.header": "Contact",
        "contactPage.subheader": "Write to us !",
        "contactPage.content.title": "Contact details",
        /* -- documents page -- */
        "documentsPage.header": "Documents",
        "documentsPage.subheader": "Important information",
        "documentsPage.header.for.clients": "For client",
        "documentsPage.header.for.restaurants": "For Restaurants",
        "documentsPage.for.clients.document.1":
          "Regulations for the provision of services by MojStolik via the Application for the Customer",
        "documentsPage.for.clients.document.2":
          "Information on the processing of personal data",
        "documentsPage.header.for.restaurants.document.1":
          "Regulations for the provision of the service of sharing the MojStolik Application for Restaurants",
        "documentsPage.header.for.restaurants.document.2":
          "Agreement for entrusting the processing of personal data to the Restaurant",
        "documentsPage.header.for.restaurants.document.3":
          "Personal data of mojstolik.pl",
        /* -- about page -- */
        "aboutPage.header": "About",
        "aboutPage.subheader": "",
        "aboutPage.applicationName": "Mojstolik.pl",
        "aboutPage.application.description":
          " - is a Polish system created by a group of friends - mysterious lovers of good food and the art of hosting in restaurants. It is a technological solution that helps to build a community around places attractive through good food and respect for the time and habits of its loyal guests. MojStolik.pl is a growing group of restaurants that jointly do everything to make Polish gastronomy synonymous with excellent quality, attention to detail and respect for fans of spending time at the table with friends, family or business partners. The founders of the system translated their restaurant passion into the language of technology and thanks to this a gastronomic portal and application were created that connect people at the tables of the most valued and popular restaurants in Poland.",
        "aboutPage.application.header1":
          "Want to book a table in a restaurant? Now you can do it using the application on your phone or from a portal that brings together many restaurants",
        "aboutPage.application.description1":
          "Mojstolik.pl is a new comprehensive booking platform that has recently been available for Warsaw restaurants. It allows catering establishments to conveniently manage table reservations in real time and integrates all communication channels with guests: telephone, email, MojStolik mobile application and the so-called. walk-in. Thanks to it, restaurant managers have access to a dynamic view of the room, they decide about the layout of the rooms, the number of available seats and for how long each table can be booked. An additional advantage of the system is the mechanism of SMS confirmations about the reservation with the option of canceling via a link and sending SMS to guests with a reminder about the visit.",
        "aboutPage.application.header2":
          "MojStolik.pl: a tablet instead of a booking book",
        "aboutPage.application.description2":
          "MojStolik.pl is also a tool supporting restaurants in building customer loyalty. The system allows you to build a guest database, analyze booking history, and the visit counter shows how many times a given person visits the premises. When making a reservation, guests can add comments such as table preferences, favorite dishes or wines. The new platform allows for the secure storage of customer data in accordance with the requirements of the GDPR, which is not met by the paper reservation book used by restaurants.",
        "aboutPage.application.description2b":
          "The application is used by over 100 restaurants throughout Poland, such as: Stary Dom, Restauracja Baczewskich, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE’S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari and more will be added soon.",
        "aboutPage.application.header3":
          "MojStable – a new table booking application",
        "aboutPage.application.description3":
          "MojStolik is a new mobile application that allows you to book a table in a restaurant quickly and conveniently. The application allows you to instantly check availability and book a table for a given hour, because it communicates with the restaurant in real time. To the reservation, we can add a special request or comment regarding the favorite table in the restaurant, information about allergies or other preferences, and the restaurant can answer us via chat whether a given request is possible to meet. In the MojStolik application, we can also check the current menu of the restaurant, we also have access to special promotions prepared for us by the restaurants.",
        "aboutPage.contact.data": "Contact details",
        "aboutPage.contact.street": "st.",
        /* -- registration page -- */
        "registrationPage.header": "Registration",
        "registrationPage.subheader":
          "As a logged in user you can reserve tables faster and view your booking history.",
        "registrationPage.columns.header.registerStandard": "Register standard",
        "registrationPage.columns.header.registerWith": "Register with",
        "registrationPage.input.label.firstName": "First name",
        "registrationPage.input.placeholder.firstName": "E.g. George",
        "registrationPage.input.label.lastName": "Last name",
        "registrationPage.input.placeholder.lastName": "E.g. Jones",
        "registrationPage.input.label.phoneNumber": "Phone number",
        "registrationPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
        "registrationPage.input.label.email": "E-mail address",
        "registrationPage.input.placeholder.email": "E.g. address@email.com",
        "registrationPage.paragraph.optionalFields": "Optional fields",
        "registrationPage.input.label.birthDate": "Date of birth",
        "registrationPage.input.placeholder.birthDate": "E.g. 1976-10-25",
        "registrationPage.input.label.postalCode": "Post code",
        "registrationPage.input.placeholder.postalCode": "E.g. 03-123",
        "registrationPage.input.label.city": "City",
        "registrationPage.input.placeholder.city": "E.g. Warsaw",
        "registrationPage.input.label.streetAndLocalNumber":
          "Street and local number",
        "registrationPage.input.placeholder.streetAndLocalNumber":
          "E.g. Grójecka 130/105",
        "registrationPage.input.label.password": "Password",
        "registrationPage.input.placeholder.password": "",
        "registrationPage.input.label.passwordRepeat": "Password repeat",
        "registrationPage.input.placeholder.passwordRepeat": "",
        "registrationPage.header.statements": "Statements",
        "registrationPage.input.label.agreementPersonalData":
          "[EN] Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MójStolik.",
        "registrationPage.input.label.agreementPersonalData.extension":
          "agreement with the customer mojstolik.pl",
        "registrationPage.input.label.agreementReadWithdrawal":
          "[EN] Zapoznałem się z pouczeniem o prawie odstąpienia od",
        "registrationPage.input.label.agreementReadWithdrawal.extension":
          "agreement with the customer mojstolik.pl",
        "registrationPage.input.label.agreementMarketing":
          "Marketing agreement",
        "registrationPage.input.label.agreementMarketing.extension":
          "Agreement",
        "registrationPage.input.label.promotionReadWithdrawal.extension":
          "Promotion Agreement",
        "registrationPage.input.label.agreementThirdPartDataUsage":
          "[EN] Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji",
        "registrationPage.input.label.agreementThirdPartDataUsage.extension":
          "at this link",

        "registrationPage.button.register": "Register",
        "registrationPage.message.sendCodeAgain":
          "The SMS code has been resent.",
        "registrationPage.message.accountCreated": "Account has been created.",
        "registrationPage.message.registrationTimeExpired":
          "Registration time has expired",
        "registrationPage.message.enterCodeFromSMS":
          "Enter the code from the SMS.",
        "registrationPage.paragraph.timeIsOver": "The time is over.",
        "registrationPage.paragraph.timeLeft": "Left",
        "registrationPage.paragraph.seconds": "second(-s)",
        "registrationPage.header.SMSConfirmation": "SMS confirmation",
        "registrationPage.paragraph.SMSConfirmationDescription":
          "Enter the SMS code we sent to your phone number to complete registration.",
        "registrationPage.paragraph.enterSMSCodeFrom":
          "Enter the SMS code from",
        "registrationPage.input.placeholder.code": "Type in code",
        "registrationPage.paragraph.sendCodeAgain":
          "The SMS code has been resent.",
        "registrationPage.button.sendCodeAgain": "Resend code",
        "registrationPage.button.confirm": "Confirm",
        /* -- not found (404) page -- */
        "notFoundPage.header": "Error 404",
        "notFoundPage.subheader": "Requested URL",
        "notFoundPage.subheaderExtend": "was not found on the server.",
        "notFoundPage.subheaderExtend2":
          "Try to check if the address provided is correct or go to",
        "notFoundPage.subheaderExtend3": "main page",
        "notFoundPage.button.goToHomePage": "Go to main page",
        /* -- paymentInfo --*/
        "global.toast.payment.count":
          "Required products have not been selected",
        "paymentInfo.description.label": "Description",
        "paymentInfo.amount.label": "Price",
        "paymentInfo.quantity.label": "Quantity",
        "paymentInfo.total.label": "Total",
        "paymentInfo.nocalculation": "Payments not required",
        "paymentInfo.summarycost.label": "Summary:",
        "paymentInfo.required.products": "Mandatory products",
        "paymentInfo.optional.products": "Optional products",
        "paymentInfo.recalculate": "Recalculate",
        "paymentInfo.amount.to.paid": "The total amount to be paid ",
        "paymentInfo.info":
          "The total number of mandatory products must be equal to the number of people in the reservation.",
        "paymentInfo.info1": "You have to choose more",
        "paymentInfo.info3": "product",
        "paymentInfo.info4": "products",
        "paymentInfo.package.title": "Basic package",
        "paymentInfo.select.product": "Select a product",
        "paymentInfo.select.basic.product": "Choose a basic product",
        "paymentInfo.select.required.product": "Select mandatory products",
        "paymentInfo.too.much.products": "You cannot select more products",
        "restaurantPage.button.confirmAndPay": "Confirm And Pay",
        "restaurantPage.button.closestTime": "Nearest available",
        "component.header.menu.event": "Event - ",
        "paymentInfo.title": "Payment",
        "reservation.payment.status.Created": "Created",
        "reservation.payment.status.WaitingForPayment": "Waiting for payment",
        "reservation.payment.status.ProcessingPayment": "Processing",
        "reservation.payment.status.Completed": "Completed",
        "reservation.payment.status.Cancelled": "Cancelled",
        "reservation.payment.status.Expired": "Expired",
        "reservation.payment.status.Unknown": "Unknown status",
        /* -- custom form -- */
        "custom.form.required": "Requied field",
        "custom.button.send": "Send",
        "custom.button.hide": "Hide",
        "custom.button.show": "Show",

        /* -- reservation confirmation -- */
        "reservation.confirmation.payment.count": "Recalculate products",
        "reservation.confirmation.selected.promotion": "Selected promotion",

        /* -- forRestaurantsPage form -- */
        "forRestaurantsPage.form.toast.success":
          "Your contact details have been sent.",
        "forRestaurantsPage.form.toast.error": "Error occured. Try again.",
        "forRestaurantsPage.form.email.error": "Invalid email",
        "forRestaurantsPage.label.name": "FIRST NAME AND LAST NAME:",
        "forRestaurantsPage.label.restaurant": "RESTAURANT:",
        "forRestaurantsPage.label.email": "E-MAIL:",
        "forRestaurantsPage.label.phone": "PHONE:",
        "forRestaurantsPage.header.agreement": "STATEMENT",
        "forRestaurantsPage.label.agreement":
          "I consent to the processing of my personal data by Table4Rest sp. z o. o. in order to conclude a contract for the provision of services for sharing and using the MojStolik.pl application and to send the offer electronically.",

        /* -- Survey Page -- */
        "surveyPage.header.title": "Customer survey",
        "surveyPage.header.description":
          "Your feedback is very important to us. Please share your feedback so we can continue to improve our services:",
        "surveyPage.button.complete": "Complete",
        "surveyPage.button.back": "Back",
        "surveyPage.button.next": "Next",
        "surveyPage.surveyCompleteMessage":
          "Thank you for completing the survey!",
        "surveyPage.emptySurveyMessage":
          "An error occured while downloading survey data. Please try again later.",
        "surveyPage.error": "An error occured.",
        "surveyPage.results.sending": "Your answer is being submitted...",
        "surveyPage.results.success": "Your answers have been submitted",
        "surveyPage.filledAlert.header":
          "This survey has already been completed",
      }),
    },
  },
  lng: "pl",
  //fallbackLng: "en",
  keySeparator: ">",
  nsSeparator: "|",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
